// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-lable {
  text-align: center !important;
  color: black !important;
}

body, * {
  font-family: "Calibri";
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/model/commingsoon/commingsoon.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,uBAAA;AACJ;;AAEA;EACI,sBAAA;EACA,YAAA;AACJ","sourcesContent":["ion-lable {\n    text-align: center !important;\n    color: black !important;\n}\n\nbody, * {\n    font-family: \"Calibri\";\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
