// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-popover {
  --width: 350px !important;
  margin-top: -150px;
  margin-left: 200px;
}

ion-popover .popover-content {
  --width: 150px !important;
  width: 150px !important;
}

.share-buttons {
  display: flex;
  gap: 10px;
}

.share-button {
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
}

.zoom {
  background-color: #0b5cff;
}

.whatsapp {
  background-color: #25d366;
}

.person {
  background-color: #528499;
}

ion-radio {
  --border-radius: 4px;
  --inner-border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
  --color: #ddd;
  --color-checked: #528499;
}

ion-radio-group {
  display: flex;
  align-items: center;
}

ion-radio.ios::part(container) {
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
}

.radio-checked.ios::part(container) {
  border-color: #528499;
}`, "",{"version":3,"sources":["webpack://./src/app/model/addschedule/addschedule.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AACA;EACI,yBAAA;EACA,uBAAA;AAEJ;;AACA;EACI,aAAA;EACA,SAAA;AAEJ;;AAAC;EACG,YAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AAGJ;;AADC;EACG,yBAAA;AAIJ;;AAFC;EACG,yBAAA;AAKJ;;AAHC;EACG,yBAAA;AAMJ;;AAFA;EACG,oBAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,wBAAA;AAKH;;AAHC;EACE,aAAA;EACC,mBAAA;AAMJ;;AAHC;EACE,WAAA;EACA,YAAA;EAEA,sBAAA;EACA,kBAAA;AAKH;;AAFC;EACE,qBAAA;AAKH","sourcesContent":["ion-popover {\n    --width: 350px !important;\n    margin-top: -150px;\n    margin-left: 200px;\n}\nion-popover .popover-content{\n    --width: 150px !important;\n    width: 150px !important;\n}\n\n.share-buttons {\n    display: flex;\n    gap: 10px;\n }\n .share-button {\n    border: none;\n    border-radius: 5px;\n    color: #fff;\n    cursor: pointer;\n    font-size: 16px;\n    padding: 8px 16px;\n }\n .zoom {\n    background-color: #0b5cff;\n }\n .whatsapp {\n    background-color: #25d366;\n }\n .person {\n    background-color: #528499;\n }\n\n//radio\nion-radio {\n   --border-radius: 4px;\n   --inner-border-radius: 4px;\n   margin-right: 10px;\n   margin-left: 5px;\n   --color: #ddd;\n   --color-checked: #528499;\n }\n ion-radio-group{\n   display: flex;\n    align-items: center;\n }\n \n ion-radio.ios::part(container) {\n   width: 20px;\n   height: 20px;\n \n   border: 2px solid #ddd;\n   border-radius: 4px;\n }\n \n .radio-checked.ios::part(container) {\n   border-color: #528499;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
