import { AuthGuard } from './../shared/authguard.service';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: "root",
})

export class ApiService {
  deviceInfo;
  bacisAuth;
  options;
  httpOptions: any;
  device_id = "";
  device_details = null;
  ProfilePopup: any;

  constructor(private ngxService: NgxUiLoaderService,public auth: AuthGuard, private http: HttpClient, private device: Device,public oneSignal: OneSignal) {
     //console.log('Device UUID is: ', this.device);
    this.deviceInfo = this.getDeviceInfo();
    this.device_id = this.device.uuid != null ? this.device.uuid : "1595831596879";
    //console.log('device_id ', this.device_id);
    this.device_details = this.device.platform;


    this.bacisAuth = 'Basic ' + btoa(environment.username + ":" + environment.password);

    this.callOneSignal();
    this.setHeaderData();

  }

dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  setHeaderData() {
    //console.log('Basic auth ' + this.bacisAuth)

    //console.log('getLoginToken ', this.getLoginToken());
    //console.log('getUserId ', this.getUserId());

    if (this.getLoginToken() == undefined) {
      this.httpOptions = {
        headers: new HttpHeaders({
         // 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers,Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        //  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',

          'Access-Control-Allow-Origin': '*',
          'Authorization': this.bacisAuth,
          'X-NATURAL-API-KEY': environment.apikey,
        })
      };
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          // 'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': "Access-Control-Allow-Headers,Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        //  'Accept': 'application/json',  For Raw data
        //  'Content-Type': 'application/json', For Raw data
          'Access-Control-Allow-Origin': '*',
          'Authorization': this.bacisAuth,
          'X-NATURAL-API-KEY': environment.apikey,
          'User-Id': this.getUserId(),
          'X-NATURAL-LOGIN-TOKEN': this.getLoginToken(),

        })
      };

    }
  }

  setHeaderDataNative() {
    if (this.auth.canActivate && this.getLoginToken() && this.getUserId()) {
      //console.log('User Info ', this.getLoginToken());
      //console.log('User Info ', this.getUserId());
      this.httpOptions = {
        'X-NATURAL-API-KEY': environment.apikey,
        'Authorization': this.bacisAuth,
        'X-NATURAL-LOGIN-TOKEN': this.getLoginToken(),
        'User-Id': this.getUserId(),
        'Access-Control-Allow-Headers': 'Accept, Content-Type, Content-Length, Accept-Encoding, Authorization, Origin'
      }
    }
  }

  Userlogin(email, password) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("email", email);
    postData.append("password", password);
    postData.append("device_id", this.device_id);   
    postData.append("device_details", this.device_details);
    postData.append("OSPlayerID", localStorage.getItem('OSPlayerID'));
    return this.http.post(environment.BaseUrl + "auth/login", postData, this.httpOptions);
  }

  UserRegister(fname,lname,mobile,email,password,CountryID,StatesID,City,InvitedID,OrganizationID,LanguageID) {
    this.setHeaderData();
    let postData = new FormData();
    postData.append("firstname", fname);
    postData.append("lastname", lname);
    postData.append("mobile_no", mobile);
    postData.append("email", email);
    postData.append("password", password);
    postData.append("countryid", CountryID);
    postData.append("stateid", StatesID);
    postData.append("city", City);
    postData.append("invitedbyid", InvitedID);
    postData.append("organizationid", OrganizationID);
    postData.append("languageid", LanguageID);
    postData.append("PaidApp", "1");

    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("player_id", localStorage.getItem('PlearID'));

    return this.http.post(environment.BaseUrl + "auth/customer_register", postData, this.httpOptions);
  }

  AddDeciple(fname,lname,mobile,email,CountryID,StatesID,City,InvitedID,OrganizationID,LanguageID) {
    this.setHeaderData();
    let postData = new FormData();
    postData.append("firstname", fname);
    postData.append("lastname", lname);
    postData.append("mobile_no", mobile);
    postData.append("email", email);
    postData.append("countryid", CountryID);
    postData.append("stateid", StatesID);
    postData.append("city", City);
    postData.append("invitedbyid", InvitedID);
    postData.append("organizationid", OrganizationID);
    postData.append("languageid", LanguageID);

    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("player_id", localStorage.getItem('PlearID'));

    return this.http.post(environment.BaseUrl + "disciple/discipleaddadd", postData, this.httpOptions);
  }
  Churchlogin(username, password) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("username", username);
    postData.append("password", password);
    postData.append("device_id", this.device_id);   
    postData.append("device_details", this.device_details);
    postData.append("OSPlayerID", localStorage.getItem('OSPlayerID'));
    return this.http.post(environment.BaseUrl + "auth/login_church", postData, this.httpOptions);
  }

  ChurchRegister(churchname,fname,lname,mobile,email,username,password,CountryID,StatesID,City) {
    this.setHeaderData();
    let postData = new FormData();
    postData.append("churchname", churchname);
    postData.append("firstname", fname);
    postData.append("lastname", lname);
    postData.append("mobile_no", mobile);
    postData.append("email", email);
    postData.append("username", username);
    postData.append("password", password);
    postData.append("countryid", CountryID);
    postData.append("stateid", StatesID);
    postData.append("city", City);

    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("player_id", localStorage.getItem('PlearID'));

    return this.http.post(environment.BaseUrl + "auth/church_register", postData, this.httpOptions);
  }

  getchurchchat(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'chat/messageslist',postData, this.httpOptions);
  }
  
  ChurchSendMassage(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'chat',postData, this.httpOptions);
  }
  DiscipleMassage(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'DiscipleChat/messageslist',postData, this.httpOptions);
  }
  DiscipleChat(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'DiscipleChat',postData, this.httpOptions);
  }
  // ChurchSendMassage(data): any {
  //   var httpOptions = {
  //     headers: new HttpHeaders({
  //       'Access-Control-Allow-Origin': '*',
  //       'Authorization': this.bacisAuth,
  //       'X-CI-HELICOPTER-API-KEY': environment.apikey,
  //       'X-CI-HELICOPTER-LOGIN-TOKEN': this.getLoginToken(),
  //       'User-Id': this.getUserId(),
  //     })
  //   }
  //   return this.http.post(environment.BaseUrl + 'SupportChat', data, httpOptions);
  // }

  GetCountryList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'countries/countries_list', this.httpOptions);
  }

  GetVideoList() {
    this.setHeaderData();   
    return this.http.get(environment.BaseUrl + "video/videolist", this.httpOptions);
  }
  GetStateList(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "countries/states", postData, this.httpOptions);
  }
  GetUserPrayerList(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "prayer/getuserprayer", postData, this.httpOptions);
  }
  AcceptDeciple(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "user/Acceptingdisciples ", postData, this.httpOptions);
  }
  AddComments(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "comments", postData, this.httpOptions);
  }
  getComments() {
    this.setHeaderData();   
    return this.http.get(environment.BaseUrl + "comments", this.httpOptions);
  }
  GetUserPrayeredList(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "prayer/getprayeruserinfo", postData, this.httpOptions);
  }
  UpdateMyProfile(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "user/update_profile", postData, this.httpOptions);
  }

  GetLanguageList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'countries/language', this.httpOptions);
  }
  GetStepList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'badge/steplist', this.httpOptions);
  }
  GetStatusList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'Status/statuslist', this.httpOptions);
  }
  SetStepStatus(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "badge/stepcompleted", postData, this.httpOptions);
  }
  SetDiscipleStatus(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "Status/statusupdate", postData, this.httpOptions);
  }
  getInvitedList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'organization/invitedby', this.httpOptions);
  }
  getOrganizationList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'organization/organizationlist', this.httpOptions);
  }

  getHomeData(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + "homecms/homeinfo", this.httpOptions);
  }
  getStories(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + "stories/storie", this.httpOptions);
  }

  AddPrayerReq(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "prayer/prayeradd", postData, this.httpOptions);
  }
  GetFAQList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'faq/faqlist', this.httpOptions);
  }
  GetRecommList(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'recommended_resources/recommended_resources',postData, this.httpOptions);
  }
  GetCategoryList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'recommended_resources/resources_category', this.httpOptions);
  }

  ChurchInq(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "churcheenquiry/churcheenquiryadd", postData, this.httpOptions);
  }
 
  forgotpassword(email) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("email", email);
    postData.append("device_id", this.device_id);   
    postData.append("device_details", this.device_details);
    postData.append("OSPlayerID", localStorage.getItem('OSPlayerID'));
    return this.http.post(environment.BaseUrl + "auth/forgot_password", postData, this.httpOptions);
  }

  ChangePassword(new_password,confirm_new_password){
    this.setHeaderData();

    let postData = new FormData();
    postData.append("new_password",new_password);
    postData.append("confirm_new_password",confirm_new_password);
    postData.append("device_id", this.device_id);   
    postData.append("device_details", this.device_details);
    postData.append("OSPlayerID", localStorage.getItem('OSPlayerID'));
    return this.http.post(environment.BaseUrl + "auth/change_password", postData, this.httpOptions);
  }
  GetPlanDetails(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'plans/planList', this.httpOptions);
  }
  BuyPlan(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "plans/buyplan", postData, this.httpOptions);
  }

  GetDiscipleList(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'disciple/discipleList',postData,this.httpOptions);
  }
  GetDiscipleSearchList(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'disciple/discipleserch',postData,this.httpOptions);
  }
  
  GetHowToList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'howto/natural_discipleship', this.httpOptions);
  }
  GetPrayerList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'prayer/prayerlist', this.httpOptions);
  }
  GetNotificationSettingList() {
    this.setHeaderData();   
    return this.http.get(environment.BaseUrl + "Notificationsetting", this.httpOptions);
  }
  UpdateNotificationSettingList(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "Notificationsetting/updatenotification", postData,this.httpOptions);
  }
  GetNotificationList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'notification/notification', this.httpOptions);
  }
  UpdateNotificationList(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'notification/updatenotification',postData, this.httpOptions);
  }
  GetQueList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'user/theque', this.httpOptions);
  }
  AccpetQue(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'user/acceptque',postData, this.httpOptions);
  }
  IPRAY(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'prayer/changestatus',postData, this.httpOptions);
  }
  getDescipleUserDetails(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'User/discipleuser',postData, this.httpOptions);
  }
  getBadgeList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'Badge/badgelist', this.httpOptions);
  }
  getProfileInfo(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'user/userinfo', this.httpOptions);
  }
  AddRMDiscipleFav(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "favoritecontacts/favoritecontactsadd", postData, this.httpOptions);
  }
  AddRecentView(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "recentviewed/recentviewe", postData, this.httpOptions);
  }

  GetRegionalList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'user/regionalLeader', this.httpOptions);
  }
/////////////////////////////////////////
  getAddSchedule(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'addschedule/scheduleadd',postData, this.httpOptions);
  }
  addSchedule(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'Schedule/add_schedule',postData,this.httpOptions);
  }
  monthwiseDetail(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'Schedule/monthwise_detail',postData,this.httpOptions);
  }
  deleteEvent(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'Schedule/delete_schedule',postData,this.httpOptions);
  }
  updateEvent(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'Schedule/update_schedule',postData,this.httpOptions);
  }

  contactUs(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'contactus/contactus',postData, this.httpOptions);
  }
  addNewChurch(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'organization/churchadd',postData, this.httpOptions);
  }
  addNewStory(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'Stories/storieadd',postData, this.httpOptions);
  }
  addRecommand(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'recommended_resources/recommended_resources_add',postData, this.httpOptions);
  }
  getFilterList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'disciple/filtrcount', this.httpOptions);
  }

  GetGraphicsList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'metrics', this.httpOptions);
  }
  
  GetTreeViewList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'metrics/treeview', this.httpOptions);
  }

  ShareDiscipleList(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'disciple/share_profile',postData,this.httpOptions);
  }

















  sendOtp(mobileNo) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("mobile_no", mobileNo);

    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("PlayerID", localStorage.getItem('PlearID'));


    return this.http.post(environment.BaseUrl + "auth/send_otp", postData, this.httpOptions);
  }
  sendRegisterOtp(mobileNo) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("mobile_no", mobileNo);

    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("PlayerID", localStorage.getItem('PlearID'));


    return this.http.post(environment.BaseUrl + "auth/send_otp_register", postData, this.httpOptions);
  }

  verifyOtp(mobileNo,OTP) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("mobile_no", mobileNo);
    postData.append("otp", OTP);

    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("player_id", localStorage.getItem('PlearID'));


    return this.http.post(environment.BaseUrl + "auth/verify_login_otp", postData, this.httpOptions);
  }
 

  UpcomingGamesList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'game/upcomingGames', this.httpOptions);
  }
  GetScrip(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'status/scripmaster_list', this.httpOptions);
  }
  GameDetails(GameID) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("GameID", GameID);

    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("player_id", localStorage.getItem('PlearID'));


    return this.http.post(environment.BaseUrl + "game/gamedetail", postData, this.httpOptions);
  }
  GetOption(GameID,ScriptID) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("GameID", GameID);
    postData.append("ScriptID", ScriptID);
    return this.http.post(environment.BaseUrl + "game/forecasting_option", postData, this.httpOptions);
  }
  GameJoin(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "game/joinGame", postData, this.httpOptions);
  }
  GameJoinUserValue(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "game/joinusercontest", postData, this.httpOptions);
  }

  MyGameEdit(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "game/contestedit", postData, this.httpOptions);
  }

  MyGameList(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'game/myGame', this.httpOptions);
  }
  MyGameDetails(GameID) {
    this.setHeaderData();

    let postData = new FormData();
    postData.append("GameID", GameID);

    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("player_id", localStorage.getItem('PlearID'));

    return this.http.post(environment.BaseUrl + "game/Joingamedetail", postData, this.httpOptions);
  }
  GetWalletDetails(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'wallet/getWalletAmount', this.httpOptions);
  }
  AddWalletAmt(postData) {
    this.setHeaderData();   
    return this.http.post(environment.BaseUrl + "wallet/monneyadd", postData, this.httpOptions);
  }
  SaveProfile(postData) {
    this.setHeaderData();
    postData.append("device_id", this.device_id);
    postData.append("device_details", this.device_details);
    postData.append("player_id", localStorage.getItem('PlearID'));
    return this.http.post(environment.BaseUrl + "auth/edit_profile", postData, this.httpOptions);
  }

  GetGameHistory(): any {
    this.setHeaderData();
    return this.http.get(environment.BaseUrl + 'history/gameHistory', this.httpOptions);
  }
  
  GetGameResult(postData): any {
    this.setHeaderData();
    return this.http.post(environment.BaseUrl + 'history/viewHistory',postData, this.httpOptions);
  }




  // GET & SET USER DATA
 
  setUserData(userData, login_token) {
    //console.log(userData)
    window.localStorage.setItem('killtechno_user_data', JSON.stringify(userData));
    if (login_token != '')
      window.localStorage.setItem('login_token', login_token);
    window.localStorage.setItem('user_id', userData.id);
  }

  getUserData() {
    if (window.localStorage['killtechno_user_data']) {
      return JSON.parse(window.localStorage['killtechno_user_data']);
    }
    return;
  }
 
  setPlanData(planDetail) {
    //console.log("planDetail>>",planDetail)
    window.localStorage.setItem('planDetail', JSON.stringify(planDetail));
  }

  getPlanData() {
    if (window.localStorage['planDetail']) {
      return JSON.parse(window.localStorage['planDetail']);
    }
    return;
  }
  ShowUpdateProfil(Key) {
    this.ProfilePopup = Key;
  }
  getUpdateProfil() {
    return this.ProfilePopup;
  }

  getUserId() {
    if (window.localStorage['user_id']) {
      return window.localStorage['user_id'];
    }
    return;
  }

  getLoginToken() {
    if (window.localStorage['login_token']) {
      return window.localStorage['login_token'];
    }
    return;
  }


  // GET & SET DEVICE INFO
  setDeviceInfo(deviceInfo) {
    window.localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
  }

  getDeviceInfo() {
    if (window.localStorage['deviceInfo']) {
      return JSON.parse(window.localStorage['deviceInfo']);
    }
    return;
  }

  callOneSignal() {
    this.oneSignal.startInit('9b0e84fb-5e5c-42dc-8582-84ef7c9e4c52','546015657170');

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    
    this.oneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
     });
     
     this.oneSignal.handleNotificationOpened().subscribe(() => {
       // do something when a notification is opened
     });
  
    this.oneSignal.endInit();
    this.oneSignal.getIds().then((id) => {
      //console.log('userId login ==> ',id.userId);
      //console.log('pushToken ==> ',id.pushToken);
      localStorage.setItem('PlearID',id.userId);
    });
  }
}