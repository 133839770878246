import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { FormBuilder } from '@angular/forms';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
// import { PayPal, PayPalConfiguration, PayPalPayment } from '@ionic-native/paypal/ngx';

@Component({
  selector: 'app-paypalpopup',
  templateUrl: './paypalpopup.component.html',
  styleUrls: ['./paypalpopup.component.scss'],
})
export class PaypalPopupModelComponent  {
  // For Paypal  
  paymentAmount:any;
  currency: string = 'USD';
  currencyIcon: string = '$';

  OrderID:any;

  PlanID='';

  storewebview:any;
  options: InAppBrowserOptions = {
  };
  // public payPal:PayPal,
  constructor(public navParams: NavParams,public formBuilder: FormBuilder, public router: Router, public tools: Tools,private iab: InAppBrowser, public modalCtrl: ModalController) {
    this.PlanID = this.navParams.get("PlanID");
    this.paymentAmount = this.navParams.get("AMT");

    console.log("plan id ###",this.PlanID)
    console.log("paymentAmount>>>",this.paymentAmount)

    // Paypal Payment Calling
    
    let _this = this;
    setTimeout(() => {
      // Render the PayPal button into #paypal-button-container
      <any>window['paypal'].Buttons({

        // Set up the transaction
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: _this.paymentAmount
              }
            }],
            application_context: { shipping_preference: 'NO_SHIPPING' }
          });
        },

        // Finalize the transaction
        onApprove: function (data, actions) {
          return actions.order.capture()
            .then(function (details) {
              // Show a success message to the buyer
              // alert('Transaction completed by ' + details.payer.name.given_name + '!');
              console.log("paymend_done>>>>>>", details)
              console.log("paymend_done>>>>>>", JSON.stringify(details))
              console.log("paymend_IDDD>>>>>>", details.id)
              _this.OrderID=details.id;
              document.getElementById('paymentDivSuccess').style.display = "block";
              document.getElementById('organization').style.display = "none";
              document.getElementById('paymentDivFailed').style.display = "none";
              setTimeout(() => {
                console.log("Innn >>>>>>")
                  modalCtrl.dismiss();
              }, 5000);

            })
            .catch(err => {
              console.log("paymenterror >>>>",err);
              document.getElementById('paymentDivSuccess').style.display = "none";
              document.getElementById('organization').style.display = "none";
              document.getElementById('paymentDivFailed').style.display = "block";
              setTimeout(() => {
                console.log("Innn >>>>>>")
                  modalCtrl.dismiss();
              }, 4000);

            })
        },
        onCancel:function(param){
          console.log("onCancel>>>>", param);
          document.getElementById('paymentDivSuccess').style.display = "none";
          document.getElementById('organization').style.display = "none";
          document.getElementById('paymentDivFailed').style.display = "block";
          setTimeout(() => {
            console.log("Innn >>>>>>")
              modalCtrl.dismiss();
          }, 4000);
        },
        onError:function(param){
          console.log("onError>>>>", param);
          document.getElementById('paymentDivSuccess').style.display = "none";
          document.getElementById('organization').style.display = "none";
          document.getElementById('paymentDivFailed').style.display = "block";
          setTimeout(() => {
            console.log("Innn >>>>>>")
              modalCtrl.dismiss();
          }, 4000);
        },
      }).render('#paypal-button-container');
    }, 500)
  
  }

  ionViewWillEnter() {
    // this.PayNow();
  }
 
  dismissModal() {
    this.modalCtrl.dismiss('');
  }
  close() {
    this.modalCtrl.dismiss('');
  }

  // For Mobile Payopal But not Working
  payWithPaypal() {
    // this.payPal.init({
    //   PayPalEnvironmentProduction: 'AdNlxeiNaM-SuFSpBGD47K_yQnlXS_S9JdhAyXEWhqeYFrhy7fIpzrwlwST9pBvGpHiO9-SsyIKpuBa1',
    //   PayPalEnvironmentSandbox: 'AUYC5C1nqAy1pl99Yagz8Ved_sbzLeRceCVdvezj1dvnRbwBTyCpO29g4k5bUISSyg1qKVUYRE2FP4UJ'
    // }).then(() => {
    //   // Environments: PayPalEnvironmentNoNetwork, PayPalEnvironmentSandbox, PayPalEnvironmentProduction
    //   this.payPal.prepareToRender('PayPalEnvironmentSandbox', new PayPalConfiguration({
    //     // Only needed if you get an "Internal Service Error" after PayPal login!
    //     //payPalShippingAddressOption: 2 // PayPalShippingAddressOptionPayPal
    //   })).then(() => {
    //     let payment = new PayPalPayment(this.paymentAmount, this.currency, 'Description', 'sale');
    //     this.payPal.renderSinglePaymentUI(payment).then((res) => {
    //       console.log(res);
    //       alert('Transaction completed');
    //       // Successfully paid
    //     }, () => {
    //       alert('Error or render dialog closed without being successful');
    //       // Error or render dialog closed without being successful
    //     });
    //   }, () => {
    //     alert('Error in configuration');
    //     // Error in configuration
    //   });
    // }, () => {
    //   alert('Error in initialization maybe PayPal isn;t supported or something else');
    //   // Error in initialization, maybe PayPal isn't supported or something else
    // });
  }
  
  PayNow(){
    console.log("Open Browser");
    let target = "_target";
    const browser = this.iab.create('https://www.paypal.com/donate/?hosted_button_id=WBXNYPKQ3DUXC',target,this.options);
    browser.on("loadstop").subscribe((event) => {
      var url = new URL(event.url);
      console.log("loadstop ==> ", event.url);
      console.log('Call back Url Data ',url);
    });

    browser.on("exit").subscribe(
      (event) => {
        console.log("Log ", event);
      }
    );
  }
}
