import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service-new';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
@Component({
  selector: 'app-addschedule',
  templateUrl: './addschedule.component.html',
  styleUrls: ['./addschedule.component.scss'],
})
export class AddScheduleModelComponent {
  loginForm: FormGroup;
  minDate: any = new Date().toISOString();
  date = new Date();
  FROM = '';
  DID = '';
  GameID = '';
  GamePriceID = '';

  UserSelectedValue = [];
  guestlist = [];
  autoguestnew = [];
  @ViewChild('rating') rating: any;
  IsEvent: any = true;
  IsTask: any = false;
  isWp: any = false;
  isZoom: any = false;
  isUpdate: any = false;
  addtittle: string;
  addguests: string;
  addlocation: string;
  addeventdescription: string;
  tags = ['Ionic', 'Angular', 'TypeScript'];

  adddtaskescription: string;

  added = [];
  InvitedList = [];
  InvitedID = '';
  InvitedName = '';
  selDate: any;
  ngwhatsapp: any;
  editEvent: any;
  ngzoom: any;
  eventortask: any;
  radio_ev: any = 'EVENT';
  edit_date: any;
  disciple_Select:any;
  public Invresults = [...this.guestlist];
  public options = {
    readonly: undefined,
    placeholder: '+ asckajscbakbscj',
  };
  constructor(
    private ngxService: NgxUiLoaderService,
    public navParams: NavParams,
    public router: Router,
    private apiService: ApiService,
    public tools: Tools,
    public formBuilder: FormBuilder,
    public modalCtrl: ModalController
  ) {
    this.FROM = this.navParams.get('FROM');
    this.DID = this.navParams.get('ID');
    this.GameID = this.navParams.get('GameID');
    this.GamePriceID = this.navParams.get('GamePriceID');
    this.editEvent = this.navParams.get('data');

    //set data edit
    if(this.navParams.get('data') != undefined){
      this.isUpdate = true;
      // this.eventId = this.editEvent.ScheduleID
      this.addtittle = this.editEvent.Title;
      this.edit_date = moment(this.editEvent.startTime).format('DD MMM yyyy HH:mm');
      this.selDate = moment(this.editEvent.startTime).format('YYYY-MM-DDTHH:mm:ss');
      if (this.editEvent.EventOrTask == 'EVENT') {
        this.IsEvent = true;
        this.IsTask = false;
        this.radio_ev = 'EVENT'
        this.addeventdescription = this.editEvent.Description;
        this.disciple_Select = this.editEvent.Desciples.split(',');
        if(this.editEvent.Meeting.split(' ')[0] == 'Whatsapp'){
          this.addlocation = 'w';
          this.isWp = true;
          this.ngwhatsapp = this.editEvent.Meeting.trim().slice(11)
        }else if(this.editEvent.Meeting.split(' ')[0] == 'Zoom'){
          this.addlocation = 'z';
          this.isZoom = true;
          this.ngzoom = this.editEvent.Meeting.trim().slice(7)
        }
      }else if(this.editEvent.EventOrTask == 'TASK'){
        this.IsTask = true;
        this.IsEvent = false;
        this.radio_ev = 'TASK'
        this.disciple_Select = this.editEvent.Desciples.split(',');
        this.adddtaskescription = this.editEvent.Description;

      }
    }
    
    console.log("edit>>>",this.editEvent)
    console.log("isUpdate>>>",this.isUpdate)
  }
  radioGroupChange(CNTID) {
    this.eventortask = CNTID.target.value;
    console.log(this.eventortask);
    // this.Isshow=true;
    if (CNTID.target.value == 'EVENT') {
      this.IsEvent = true;
      this.IsTask = false;
    } else if (CNTID.target.value == 'TASK') {
      this.IsTask = true;
      this.IsEvent = false;
    }
  }
  onChangeDate(d) {
    this.date = d.target.value;
  }
  meetlocation(ev) {
    console.log(this.addlocation);
    if (ev.detail.value == 'w') {
      this.isWp = true;
      this.isZoom = false;
    } else if (ev.detail.value == 'z') {
      this.isZoom = true;
      this.isWp = false;
    }
  }
  ionViewWillEnter() {
    // this.AddSchedule();
    console.log("FROM>>>",this.FROM)
    console.log("DID>>>",this.DID)
    this.GetDiscipleList('ALL');
    if(this.FROM == 'DD'){
      this.disciple_Select = this.DID
    }
  }
  onChange(val) {
    console.log("chips >>",val)
    this.added.push({ id: val.id, name: val.name });
  }
  onItemRemoved(val) {
    for (let index = 0; index < this.added.length; index++) {
      const element = this.added[index];
      if (element.id == val.id) {
        this.added.splice(index, 1);
      }
    }
  }
  InvitedHandleChange(Key) {
    if (Key == undefined || Key == '') {
      this.Invresults = [];
    } else {
      const query = Key.toLowerCase();
      this.Invresults = this.guestlist.filter(
        (d) => d.CustomerName.toLowerCase().indexOf(query) > -1
      );
    }
  }
  InvitedSelect(id, name) {
    console.log(id);
    console.log(name);
    this.InvitedID = id;
    this.InvitedName = name;
    this.Invresults = [];
  }
  GetDiscipleList(key) {
    if (this.tools.isNetwork()) {
      // this.tools.openLoader();
      // this.ngxService.startLoader("loader-01");
      let postData = new FormData();
      postData.append('FilterKey', key);
      this.apiService.GetDiscipleList(postData).subscribe(
        (data) => {
          let res: any = data;
          console.log(' GetDiscipleList > ', res.data.Disciple);
          this.guestlist = res.data.Disciple;
          for (let index = 0; index < this.guestlist.length; index++) {
            const element = this.guestlist[index];
            this.autoguestnew.push({
              name: element.CustomerName,
              id: element.DescipleID,
            });
          }
          console.log(this.autoguestnew);
        },
        (error: Response) => {
          // this.tools.closeLoader();
          this.ngxService.stopLoader('loader-01');
          console.log(error);
          let err: any = error;
          this.tools.openAlertToken(err.status, err.error.message);
        }
      );
    } else {
      // this.tools.closeLoader();
      this.ngxService.stopLoader('loader-01');
    }
  }
  saveEvent() {
    var msg = '';

    if (this.addtittle == undefined) {
      msg = msg + 'Please enter Tittle.';
    }
      if (this.disciple_Select == undefined || this.disciple_Select == "") {
      msg = msg + 'Please Select  Disciples.';
    }

    if (this.IsEvent) {
     
      if (this.addlocation == undefined) {
        msg = msg + 'Please Select meeting Type. ';
      }
      
      if (this.isWp && this.ngwhatsapp == undefined) {
          msg = msg + 'Please enter Whatsapp No.';
        }
       
      if (this.isZoom  && this.ngzoom == undefined) {
          msg = msg + 'Please enter Zoom Link.';
        }
      if (this.addeventdescription == undefined) {
          msg = msg + 'Please enter Description.';
      }

    } else {
      if (this.adddtaskescription == undefined) {
        msg = msg + 'Please enter Description.';
      }
    }
    

    if (msg != '') {
      this.tools.openAlert(msg);
    } else {
      this.AddSchedule();
    }
  }
  updateEvent() {
    var msg = '';

    if (this.addtittle == undefined) {
      msg = msg + 'Please enter Tittle.';
    }

    if (this.IsEvent) {
      if (this.disciple_Select == undefined) {
        msg = msg + 'Please Select  Disciples.';
      }
      if (this.addlocation == undefined) {
        msg = msg + 'Please Select meeting Type. ';
      }
      
      if (this.isWp && this.ngwhatsapp == undefined) {
          msg = msg + 'Please enter Whatsapp No.';
        }
       
      if (this.isZoom  && this.ngzoom == undefined) {
          msg = msg + 'Please enter Zoom Link.';
        }
      if (this.addeventdescription == undefined) {
          msg = msg + 'Please enter Description.';
      }

    } else {
      if (this.adddtaskescription == undefined) {
        msg = msg + 'Please enter Description.';
      }
    }

    if (msg != '') {
      this.tools.openAlert(msg);
    } else {
      this.updateSchedule();
    }
  }
  discipleSelect(ev){
    // console.log("multi dis >>>>",ev)
    console.log("multi dis >>>>",this.disciple_Select)
    console.log("multi dis >>>>",this.disciple_Select.join(', '))
    this.disciple_Select = this.disciple_Select

  }
  AddSchedule() {
    if (this.tools.isNetwork()) {
      this.ngxService.startLoader('loader-01');
      let postData = new FormData();
      postData.append('Title', this.addtittle);
      postData.append('EventOrTask', this.IsEvent ? 'EVENT' : this.eventortask);
      postData.append('Meeting', this.isWp ? "Whatsapp : "+this.ngwhatsapp : "Zoom : "+this.ngzoom);
      postData.append('wp_or_zoom', this.isWp ? "Whatsapp": "Zoom");
      postData.append('Description',this.IsEvent ? this.addeventdescription : this.adddtaskescription);
      const arrayDataString = JSON.stringify(this.added);
      // postData.append('Desciples', arrayDataString);
      postData.append('Desciples', this.disciple_Select);
      postData.append('startTime',this.selDate == undefined ? moment(this.date).format('YYYY-MM-DDTHH:mm:ss') : moment(this.selDate).format('YYYY-MM-DDTHH:mm:ss'));
      postData.append('timezone',this.selDate == undefined ? moment(this.date).format('Z') : moment(this.selDate).format('Z'));
      postData.append('endTime',this.selDate == undefined ? moment(this.date).format('YYYY-MM-DDTHH:mm:ss') : moment(this.selDate).format('YYYY-MM-DDTHH:mm:ss'));
      this.apiService.addSchedule(postData).subscribe(
        (data) => {
          this.ngxService.stopLoader('loader-01');
          let res: any = data;
          console.log(' getAddSchedule > ', res);
          // this.tools.openAlert(res.message);
          this.modalCtrl.dismiss('done');
        },
        (error: Response) => {
          this.ngxService.stopLoader('loader-01');
          console.log(error);
          let err: any = error;
          this.tools.openAlertToken(err.status, err.error.message);
        }
      );
    } else {
      this.ngxService.stopLoader('loader-01');
    }
  }
  updateSchedule() {
    if (this.tools.isNetwork()) {
      this.ngxService.startLoader('loader-01');
      let postData = new FormData();
      postData.append('Title', this.addtittle);
      postData.append('EventOrTask', this.IsEvent ? 'EVENT' : 'TASK');
      postData.append('Meeting', this.isWp ? "Whatsapp : "+this.ngwhatsapp : "Zoom : "+this.ngzoom);
      postData.append('wp_or_zoom', this.isWp ? "Whatsapp": "Zoom");
      postData.append('Description',this.IsEvent ? this.addeventdescription : this.adddtaskescription);
      const arrayDataString = JSON.stringify(this.added);
      // postData.append('Desciples', arrayDataString);
      postData.append('Desciples', this.disciple_Select);
      postData.append('startTime',this.selDate == undefined ? moment(this.date).format('YYYY-MM-DDTHH:mm:ss') : moment(this.selDate).format('YYYY-MM-DDTHH:mm:ss'));
      postData.append('timezone',this.selDate == undefined ? moment(this.date).format('Z') : moment(this.selDate).format('Z'));
      postData.append('endTime',this.selDate == undefined ? moment(this.date).format('YYYY-MM-DDTHH:mm:ss') : moment(this.selDate).format('YYYY-MM-DDTHH:mm:ss'));
      postData.append('ScheduleID', this.editEvent.ScheduleID);
      this.apiService.updateEvent(postData).subscribe(
        (data) => {
          this.ngxService.stopLoader('loader-01');
          let res: any = data;
          console.log(' getAddSchedule > ', res);
          // this.tools.openAlert(res.message);
          this.modalCtrl.dismiss('done');
        },
        (error: Response) => {
          this.ngxService.stopLoader('loader-01');
          console.log(error);
          let err: any = error;
          this.tools.openAlertToken(err.status, err.error.message);
        }
      );
    } else {
      this.ngxService.stopLoader('loader-01');
    }
  }

  dismissModal() {
    this.modalCtrl.dismiss('');
  }
  close() {
    this.modalCtrl.dismiss('');
  }
}
