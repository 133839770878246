// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#paymentsuser .paymentsuser_block ion-row ion-col {
  padding: 10px !important;
  border-right: 1px solid #ccc;
}

#donate .language_box .checkmark {
  border: 1px solid #508398;
  background: transparent;
  border-radius: 5px;
}

#donate .language_box ion-radio.radio-checked ~ .checkmark {
  background: #508398 !important;
}

#donate .language_box ion-label:first-child {
  font-size: 16px !important;
  color: #000 !important;
  font-weight: bold !important;
}

#donate .language_box ion-radio.radio-checked ~ .checkmark ion-label:first-child {
  font-size: 16px !important;
  color: #fff !important;
  font-weight: bold !important;
}

.aplos-donation-button {
  height: 43px;
  width: 103px;
  font-size: 20px;
}

.aplos-donation-button-two {
  height: 43px;
  width: 103px;
  font-size: 20px;
  background-color: #508398;
}`, "",{"version":3,"sources":["webpack://./src/app/model/paymentpopup/paymentpopup.component.scss"],"names":[],"mappings":"AAIA;EACI,wBAAA;EACA,4BAAA;AAHJ;;AAMA;EACI,yBAAA;EACA,uBAAA;EACA,kBAAA;AAHJ;;AAKA;EACI,8BAAA;AAFJ;;AAIA;EACI,0BAAA;EACA,sBAAA;EACA,4BAAA;AADJ;;AAGA;EACI,0BAAA;EACA,sBAAA;EACA,4BAAA;AAAJ;;AAGA;EACI,YAAA;EACA,YAAA;EACA,eAAA;AAAJ;;AAEA;EACI,YAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;AACJ","sourcesContent":["// #paymentsuser .modal-dialog ion-segment-button:last-child {\n//     pointer-events: none !important;\n// }\n\n#paymentsuser .paymentsuser_block ion-row ion-col {\n    padding: 10px !important;\n    border-right: 1px solid #ccc;\n}\n\n#donate .language_box .checkmark {\n    border: 1px solid #508398;\n    background: transparent;\n    border-radius: 5px;\n}\n#donate .language_box ion-radio.radio-checked ~ .checkmark {\n    background: #508398 !important;\n}\n#donate .language_box ion-label:first-child {\n    font-size: 16px !important;\n    color: #000 !important;\n    font-weight: bold !important;\n}\n#donate .language_box ion-radio.radio-checked ~ .checkmark ion-label:first-child {\n    font-size: 16px !important;\n    color: #fff !important;\n    font-weight: bold !important;\n}\n\n.aplos-donation-button{\n    height: 43px;\n    width: 103px;\n    font-size: 20px;\n  }\n.aplos-donation-button-two{\n    height: 43px;\n    width: 103px;\n    font-size: 20px;\n    background-color: #508398;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
