import { Tools } from './shared/tools';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AddScheduleModelComponent } from './model/addschedule/addschedule.component';
import { AuthGuard } from './shared/authguard.service';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { EventService } from './services/EventService';
import { VideoPopupModelComponent } from './model/videopopup/videopopup.component';
import { SharePopupModelComponent } from './model/sharepopup/sharepopup.component';
import { PaymentPopUpModelComponent } from './model/paymentpopup/paymentpopup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSharePopupModelComponent } from './model/appsharepopup/appsharepopup.component';
import { MSGPopupModelComponent } from './model/msgpopup/msgpopup.component';
import { CommingSoonModelComponent } from './model/commingsoon/commingsoon.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { PrayedUsersModelComponent } from './model/prayedusers/prayedusers.component';

import { HttpClientModule } from '@angular/common/http';
import { TimeZoneService } from './services/TimeZoneService';
import { BEOrgchartModule } from 'be-orgchart';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule,
} from 'ngx-ui-loader';

import { NgCalendarModule } from 'ionic6-calendar';
import { NgModule } from '@angular/core';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { ScriptLoaderModule } from 'ngx-script-loader';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#518398',
  bgsOpacity: 0,
  bgsPosition: POSITION.bottomRight,
  bgsSize: 70,
  bgsType: SPINNER.circle,
  fgsColor: '#518398',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 70,
  fgsType: SPINNER.circle,
  hasProgressBar: false,
  // masterLoaderId: "master",
};

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BEOrgchartModule,
    BrowserAnimationsModule,
    TagInputModule,
    NgCalendarModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    PdfViewerModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    ScriptLoaderModule,
    NgSelectModule
  ],
  declarations: [
    AppComponent,
    AddScheduleModelComponent,
    PrayedUsersModelComponent,
    CommingSoonModelComponent,
    MSGPopupModelComponent,
    AppSharePopupModelComponent,
    PaymentPopUpModelComponent,
    VideoPopupModelComponent,
    SharePopupModelComponent,
  ],

  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    WebView,
    DocumentViewer,
    TimeZoneService,
    FileOpener,
    Camera,
    Tools,
    Device,
    OneSignal,
    InAppBrowser,
    AuthGuard,
    EventService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
