// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  position: relative;
}

.form-group i.pass {
  position: absolute;
  bottom: 14px;
  z-index: 111;
  font-size: 17px;
  color: #508398 !important;
  right: 15px;
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  display: block;
}

.aplos-donation-button {
  height: 43px;
  width: 103px;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/model/registerpop/registerpop.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;EACA,UAAA;EACA,cAAA;AACJ;;AACA;EACI,YAAA;EACA,YAAA;EACA,eAAA;AAEJ","sourcesContent":[".form-group {\n    position: relative;\n}\n\n.form-group i.pass {\n    position: absolute;\n    bottom: 14px;\n    z-index: 111;\n    font-size: 17px;\n    color: #508398 !important;\n    right: 15px;\n    cursor: pointer;\n    visibility: visible;\n    opacity: 1;\n    display: block;\n}\n.aplos-donation-button{\n    height: 43px;\n    width: 103px;\n    font-size: 20px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
