import { Component, QueryList, ViewChildren } from '@angular/core';
import { AlertController, IonRouterOutlet, ModalController, Platform, ToastController } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { Tools } from './shared/tools';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  constructor(private platform: Platform,public tools:Tools,public alertController: AlertController,private router: Router,public modalCtrl: ModalController,private toast:ToastController) {
    this.backButtonEvent();

        // Network.addListener('networkStatusChange', status => {
        //     console.log('Network_status_changed>>>', status);

        //             if (status.connected) {
        //                 // this.presentAlert('No internet', 'You do not have an Internet connection. Please check your connection status', 'Ok')
        //                 this.tools.openNotification("Connected")
        //                 this.tools.closeNetworkAlert();
        //             } else {
        //                 this.tools.openNotification("DisConnected")
        //                 this.tools.openNetworkAlert();
        //             }
        //   });
  }


  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(999999, async () => {
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
            element.dismiss();
            return;
        }
    } catch (error) {
        console.log(error);
    }
      console.log('subscribeWithPriority Route URL ', this.router.url);

      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        if (this.router.url === '/dashbaord') {
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
            navigator['app'].exitApp(); // work in ionic 4
          } else {
            this.presentToast('Press back again to exit App.');
            this.lastTimeBackPress = new Date().getTime();
          }
         }
        else if (outlet && outlet.canGoBack()) {
          outlet.pop();
        }
      });
    });
    this.platform.backButton.subscribe(async () => {
      console.log('subscribe Route ', this.router.url);
    });
  }
  
  async presentToast(msg) {
    const toast = await this.toast.create({
      message: msg,
      position: 'middle',
      duration: 2000
    });
    toast.present();
  }
}




