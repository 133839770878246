import { SegmentModel } from './../../shared/segment.model';
import { ApiService } from 'src/app/services/api.service-new';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams,   } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { AppSharePopupModelComponent } from '../appsharepopup/appsharepopup.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PaypalPopupModelComponent } from '../paypalpopup/paypalpopup.component';
import { RegisterPopModelComponent } from '../registerpop/registerpop.component';
@Component({
  selector: 'app-paymentpopup',
  templateUrl: './paymentpopup.component.html',
  styleUrls: ['./paymentpopup.component.scss'],
})
export class PaymentPopUpModelComponent {
  BasicPlan = '';
  AdvancePlan = '';

  PlanID = '';

  PlanList = [];
  ChurchPlan = [];
  @ViewChild('rating') rating: any;


  pageType: any = 'systemplatform'

  public segments: SegmentModel[] = [
    {
      key: 'systemplatform',
      label: 'System Platform'
    },
    {
      key: 'church',
      label: 'Church Access'
    }
  ];
  public selectedSegment: string = '';



  constructor(private ngxService: NgxUiLoaderService, public navParams: NavParams, public router: Router, private apiService: ApiService,
    private route: ActivatedRoute,
    public tools: Tools, public modalCtrl: ModalController) {

    this.selectedSegment = this.route.snapshot.fragment != null ? this.route.snapshot.fragment : 'systemplatform';
    this.pageType = this.selectedSegment
  }
  goToLink() {
    window.open('https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=KR3EXZXJHAKC4&lc=US&item_name=Coach%20Builder&amount=1%2e99&currency_code=USD&button_subtype=services&no_note=0&cn=Add%20special%20instructions%20to%20the%20seller%3a&no_shipping=2&bn=PP%2dBuyNowBF%3abtn_buynowCC_LG%2egif%3aNonHosted', 'google', 'scrollbars=yes,width=550,height=500,top=100,left=400');
  }

  ionViewWillEnter() {
    console.log("Clickk 1111")

    this.route.fragment.subscribe(
      (fragment) => {
        this.setSegment(fragment);
      }
    );
    this.GetPlan()
  }

  GetPlan() {
    if (this.tools.isNetwork()) {
      // this.tools.openLoader();
      this.ngxService.startLoader("loader-01");
      this.apiService.GetPlanDetails().subscribe(data => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");

        let res: any = data;
        console.log(' GetPlanDetails > ', res);
        this.PlanList = res.data.PlanList
        this.ChurchPlan = res.data.churchplan

      }, (error: Response) => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
        console.log(error);

        let err: any = error;
        this.tools.openAlertToken(err.status, err.error.message);
      });

    } else {
      // this.tools.closeLoader();
      this.ngxService.stopLoader("loader-01");
    }

  }
  test(){
    console.log("test")
  }
  async SelectedPlan(PlanID, PlanType, Amount) {
    this.PlanID = PlanID;
    console.log("PlanID >> ", this.PlanID)
    console.log("PlanType >> ",PlanType)

    if (PlanType == 'Free') {
      const modal = await this.modalCtrl.create({
        component: AppSharePopupModelComponent,
        cssClass: 'app-share-modal',
        componentProps: { ScriptID: "" },

      });
      await modal.present();
      await modal.onDidDismiss()
        .then((data) => {
          console.log('Selected Cart Items from Dilogs ', data);
          if (data) {
            // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
            //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
          }
        });
    } else {
      // const modal = await this.modalCtrl.create({
      //   component: PaypalPopupModelComponent,
      //   cssClass: 'PayPal-Sel-modal',
      //   componentProps: { PlanID: this.PlanID, AMT: Amount },
      //   backdropDismiss: false

      // });
      // await modal.present();
      // await modal.onDidDismiss()
      //   .then((data) => {
      //     console.log('Selected Cart Items from Dilogs ', data);
      //     if (data) {
      //       // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
      //       //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
      //     }
      //   });
      const modal = await this.modalCtrl.create({
        component: RegisterPopModelComponent,
        cssClass: 'change-success-modal',
        componentProps: { PlanID: this.PlanID, AMT: Amount },
        backdropDismiss: false

      });
      await modal.present();
      await modal.onDidDismiss()
        .then((data) => {
          console.log('Selected Cart Items from Dilogs ', data);
          if (data) {
            // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
            //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
          }
        });
    }
  }

  dismissModal() {
    this.modalCtrl.dismiss(this.PlanID);
  }
  close() {
    this.modalCtrl.dismiss('');
  }

  onClickSegment(event: Event) {
    const detail = (<CustomEvent>event).detail;
    if (detail && typeof detail.value === 'string') {
      const segment = detail.value;
      console.log("Type>>>>",segment)
      this.setSegment(segment);
      this.pageType = segment;

    }
    console.log("Clickk 2222")
  }

  setSegment(segment: string): void {
    console.log("Clickk 3333")

    if (typeof segment === 'string') {
      segment = segment.toLowerCase();
      const arrayHas = this.segments.some((candidate) => { return candidate.key === segment; });
      if (arrayHas) {
        this.selectedSegment = segment;
        this.router.navigate([], { fragment: segment }).then();
      }
    }
  }


  radioSelectAmount(Amount) {
    console.log("Basic Amount >>", Amount.target.value);
    this.BasicPlan=Amount;
    
  }
  radioSelectAdvanceAmount(Amount) {
    console.log("Advance Amount >>", Amount.target.value);
    this.AdvancePlan=Amount;

  }

}
