import { ApiService } from 'src/app/services/api.service-new';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams,   ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-prayedusers',
  templateUrl: './prayedusers.component.html',
  styleUrls: ['./prayedusers.component.scss'],
})
export class PrayedUsersModelComponent  {

  PrayeredUsers=[];

  PrayerRequestID:any;

  constructor(private ngxService: NgxUiLoaderService,public navParams: NavParams,public toastController: ToastController, public router: Router, private apiService: ApiService, public formBuilder: FormBuilder,public tools: Tools, public modalCtrl: ModalController) {
    this.PrayerRequestID = this.navParams.get("ScriptID");


   
  }

  ionViewWillEnter() {
   this.GetUserPrayeredList();
  }
 
  dismissModal() {
    this.modalCtrl.dismiss('');
  }
  close() {
    this.modalCtrl.dismiss('');
  }
 


  GetUserPrayeredList() {
    if (this.tools.isNetwork()) {
      let postData = new FormData();
      postData.append('PrayerRequestID', this.PrayerRequestID);

      // this.tools.openLoader();
      this.ngxService.startLoader("loader-01");
      this.apiService.GetUserPrayeredList(postData).subscribe(data => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");

        let res: any = data;
        console.log(' PrayeredUsers > ', res);
        this.PrayeredUsers = res.Prayer;
        console.log(' PrayeredUsers > ',  this.PrayeredUsers.length);

      }, (error: Response) => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
        console.log(error);

        let err: any = error;
        this.tools.openAlertToken(err.status, err.error.message);
      });

    } else {
      // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
    }

  }


}
