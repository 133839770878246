// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-lable {
  text-align: center !important;
  color: black !important;
}

#share .butons_box {
  margin-top: 30px;
  text-align: center;
  padding: 10px !important;
}

.modal-headers h4 {
  color: #8e9c6b;
  text-align: center;
  font-size: 20px;
}

.share-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.share-button {
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
}

.facebook {
  background-color: #3b5998;
}

.twitter {
  background-color: #1da1f2;
}

.linkedin {
  background-color: #0077b5;
}

.pinterest {
  background-color: #bd081c;
}

.reddit {
  background-color: #ff4500;
}

.whatsapp {
  background-color: #25d366;
}

.input-group {
  width: 93% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/model/sharepopup/sharepopup.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,uBAAA;AACJ;;AACA;EACI,gBAAA;EACA,kBAAA;EACA,wBAAA;AAEJ;;AAAA;EACI,cAAA;EACA,kBAAA;EACA,eAAA;AAGJ;;AADA;EACI,aAAA;EACA,SAAA;EACA,uBAAA;AAIJ;;AAFC;EACG,YAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AAKJ;;AAHC;EACG,yBAAA;AAMJ;;AAJC;EACG,yBAAA;AAOJ;;AALC;EACG,yBAAA;AAQJ;;AANC;EACG,yBAAA;AASJ;;AAPC;EACG,yBAAA;AAUJ;;AARC;EACG,yBAAA;AAWJ;;AARC;EACE,qBAAA;AAWH","sourcesContent":["ion-lable {\n    text-align: center !important;\n    color: black !important;\n}\n#share .butons_box {\n    margin-top: 30px;\n    text-align: center;\n    padding: 10px !important;\n}\n.modal-headers h4 {\n    color: #8e9c6b;\n    text-align: center;\n    font-size: 20px;\n}\n.share-buttons {\n    display: flex;\n    gap: 10px;\n    justify-content: center;\n }\n .share-button {\n    border: none;\n    border-radius: 5px;\n    color: #fff;\n    cursor: pointer;\n    font-size: 16px;\n    padding: 8px 16px;\n }\n .facebook {\n    background-color: #3b5998;\n }\n .twitter {\n    background-color: #1da1f2;\n }\n .linkedin {\n    background-color: #0077b5;\n }\n .pinterest {\n    background-color: #bd081c;\n }\n .reddit {\n    background-color: #ff4500;\n }\n .whatsapp {\n    background-color: #25d366;\n }\n\n .input-group {\n   width: 93% !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
