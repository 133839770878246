import { ApiService } from 'src/app/services/api.service-new';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams,   } from '@ionic/angular';
import { Router } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-videopopup',
  templateUrl: './videopopup.component.html',
  styleUrls: ['./videopopup.component.scss'],
})
export class VideoPopupModelComponent  {
  VideoLink='';
  GameID='';
  GamePriceID='';

  UserSelectedValue=[];
  @ViewChild('rating') rating : any;

  constructor(private ngxService: NgxUiLoaderService,public navParams: NavParams, public router: Router, private apiService: ApiService, public tools: Tools, public modalCtrl: ModalController,public dom:DomSanitizer) {
    this.VideoLink = this.navParams.get("VideoLink");
    this.GameID = this.navParams.get("GameID");
    this.GamePriceID = this.navParams.get("GamePriceID");
    console.log("link>>>",this.VideoLink)
  }

  ionViewWillEnter() {
  //  this.GameJoinUserValue();
  }
  GameJoinUserValue() {
    if (this.tools.isNetwork()) {
      // this.tools.openLoader();
      this.ngxService.startLoader("loader-01");
      let postData = new FormData();
      postData.append("GameID", this.GameID);
      postData.append("ScriptID", this.VideoLink);
      postData.append("GamePriceID", this.GamePriceID);

      this.apiService.GameJoinUserValue(postData).subscribe(data => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");

        let res: any = data;
        console.log(' GameJoinUserValue > ', res);
        this.UserSelectedValue=res.data.joinusercontest
        
      }, (error: Response) => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
        console.log(error);

        let err: any = error;
        this.tools.openAlertToken(err.status, err.error.message);
      });

    } else {
      // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
    }

  }

  dismissModal() {
    this.modalCtrl.dismiss('');
  }
  close() {
    this.modalCtrl.dismiss('');
  }

}
