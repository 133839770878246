import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Injectable()
export class TimeZoneService {
  public tenantTimeZone: string;

  constructor(private ngxService: NgxUiLoaderService,) {
    this.tenantTimeZone = 'GMT';
  }

  public setTenantTimeZone(tenantTz: string) {
    this.tenantTimeZone = tenantTz;
  }

  public utcToTenant(utcDateTime: moment.Moment): moment.Moment {
    return moment.tz(utcDateTime, this.tenantTimeZone);
  }

  public utcToTenantString(utcDateTime: moment.Moment, format: string): string {
    format = moment.tz.guess() === this.tenantTimeZone ? format : format + ' (UTC Z)';
    return moment.tz(utcDateTime, this.tenantTimeZone).format(format);
  }

  public tenantToUtc(tenantDateTime: moment.Moment): moment.Moment {
    return moment(tenantDateTime).utc();
  }
}