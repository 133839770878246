import { AuthGuard } from './shared/authguard.service';
import { DashboardPageModule } from './pages/dashboard/dashboard.module';
import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'churchlogin',
    loadChildren: () => import('./auth/churchlogin/churchlogin.module').then(m => m.ChurchLoginPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./auth/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordPageModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./auth/changepassword/changepassword.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'home',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'view',
    loadChildren: () => import('./pages/view/view.module').then( m => m.ViewPageModule)
  },
  {
    path: 'prayer',
    loadChildren: () => import('./pages/prayer/prayer.module').then( m => m.PrayerPageModule)
  },
  {
    path: 'question',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/question/question.module').then( m => m.QuestionPageModule)
  },
  {
    path: 'theque',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/theque/theque.module').then( m => m.TheQuePageModule)
  },
  {
    path: 'resources',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/resources/resources.module').then( m => m.ResourcesPageModule)
  },
  {
    path: 'subscription',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'resourcesdetail/:Type',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/resourcesdetail/resourcesdetail.module').then( m => m.ResourcesDetailPageModule)
  },
  {
    path: 'resources_sub_detail/:CatID/:CatName',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/resources_sub_detail/resources_sub_detail.module').then( m => m.Resources_Sub_DetailPageModule)
  },
  {
    path: 'howto',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/howto/howto.module').then( m => m.HowToPageModule)
  },
  {
    path: 'metrics',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/metrics/metrics.module').then( m => m.MetricsPageModule)
  },
  {
    path: 'badgedetail/:From',
    loadChildren: () => import('./pages/badgedetail/badgedetail.module').then( m => m.BadgeDetailPageModule)
  },
  {
    path: 'disciplelist',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/disciplelist/disciplelist.module').then( m => m.DiscipleListPageModule)
  },
  {
    path: 'disciplelistdetail/:DescipleID/:From',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/disciplelistdetail/disciplelistdetail.module').then( m => m.DiscipleDetailPageModule)
  },
  {
    path: 'calendar',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FAQPageModule)
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./pages/privacypolicy/privacypolicy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'termscondi',
    loadChildren: () => import('./pages/termscondi/termscondi.module').then( m => m.TermsCondiPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./pages/contactus/contactus.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'storiesdetails',canActivate: [AuthGuard],
    loadChildren: () => import('./pages/storiesdetails/storiesdetails.module').then( m => m.StoriesDetailsPageModule)
  },
  {
    path: 'prayerlist',
    loadChildren: () => import('./pages/prayerlist/prayerlist.module').then( m => m.PrayerListPageModule)
  },
  {
    path: 'myprofile',
    loadChildren: () => import('./pages/myprofile/myprofile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'notificationsetting',
    loadChildren: () => import('./pages/notificationsetting/notificationsetting.module').then( m => m.NotificationSettingPageModule)
  },
  {
    path: 'donate',
    loadChildren: () => import('./pages/donate/donate.module').then( m => m.DonatePageModule)
  },
  {
    path: 'getstarted',
    loadChildren: () => import('./pages/res/getstarted/getstarted.module').then( m => m.GetStartedPageModule)
  },
  {
    path: 'leadershipteam',
    loadChildren: () => import('./pages/res/leadershipteam/leadershipteam.module').then( m => m.LeadershipTeamPageModule)
  },
  {
    path: 'movementview',
    loadChildren: () => import('./pages/res/movementview/movementview.module').then( m => m.MovementViewPageModule)
  },
  {
    path: 'ch-intro',
    loadChildren: () => import('./pages/res/en/ch-intro/intro.module').then( m => m.IntroPageModule)
  },

  {
    path: 'ch1',
    loadChildren: () => import('./pages/res/en/ch1/one.module').then( m => m.OnePageModule)
  },
  {
    path: 'ch2',
    loadChildren: () => import('./pages/res/en/ch2/two.module').then( m => m.TwoPageModule)
  },
  {
    path: 'ch3',
    loadChildren: () => import('./pages/res/en/ch3/three.module').then( m => m.ThreePageModule)
  },
  {
    path: 'ch4',
    loadChildren: () => import('./pages/res/en/ch4/four.module').then( m => m.FourPageModule)
  },
  {
    path: 'ch5',
    loadChildren: () => import('./pages/res/en/ch5/five.module').then( m => m.FivePageModule)
  },
  {
    path: 'ch6',
    loadChildren: () => import('./pages/res/en/ch6/six.module').then( m => m.SixPageModule)
  },
  {
    path: 'ch7',
    loadChildren: () => import('./pages/res/en/ch7/seven.module').then( m => m.SevenPageModule)
  },
  {
    path: 'ch8',
    loadChildren: () => import('./pages/res/en/ch8/eight.module').then( m => m.EightPageModule)
  },
  {
    path: 'ch9',
    loadChildren: () => import('./pages/res/en/ch9/nine.module').then( m => m.NinePageModule)
  },
  {
    path: 'ch10',
    loadChildren: () => import('./pages/res/en/ch10/appendix.module').then( m => m.AppendixPageModule)
  },
  {
    path: 'ch11',
    loadChildren: () => import('./pages/res/en/ch11/pdf.module').then( m => m.PdfPageModule)
  },

  {
    path: 'fr-intro',
    loadChildren: () => import('./pages/res/fr/fr0/fr0.module').then( m => m.Fr0PageModule)
  },
  {
    path: 'fr1',
    loadChildren: () => import('./pages/res/fr/fr1/fr1.module').then( m => m.Fr1PageModule)
  },
  {
    path: 'fr2',
    loadChildren: () => import('./pages/res/fr/fr2/fr2.module').then( m => m.Fr2PageModule)
  },
  {
    path: 'fr3',
    loadChildren: () => import('./pages/res/fr/fr3/fr3.module').then( m => m.Fr3PageModule)
  },
  {
    path: 'fr4',
    loadChildren: () => import('./pages/res/fr/fr4/fr4.module').then( m => m.Fr4PageModule)
  },
  {
    path: 'fr5',
    loadChildren: () => import('./pages/res/fr/fr5/fr5.module').then( m => m.Fr5PageModule)
  },
  {
    path: 'fr6',
    loadChildren: () => import('./pages/res/fr/fr6/fr6.module').then( m => m.Fr6PageModule)
  },
  {
    path: 'fr7',
    loadChildren: () => import('./pages/res/fr/fr7/fr7.module').then( m => m.Fr7PageModule)
  },
  {
    path: 'fr8',
    loadChildren: () => import('./pages/res/fr/fr8/fr8.module').then( m => m.Fr8PageModule)
  },
  {
    path: 'fr9',
    loadChildren: () => import('./pages/res/fr/fr9/fr9.module').then( m => m.Fr9PageModule)
  },
  {
    path: 'fr10',
    loadChildren: () => import('./pages/res/fr/fr10/fr10.module').then( m => m.Fr10PageModule)
  },
  {
    path: 'fr11',
    loadChildren: () => import('./pages/res/fr/fr-pdf/fr-pdf.module').then( m => m.FrPdfPageModule)
  },
  {
    path: 'sp0',
    loadChildren: () => import('./pages/res/sp-ch/sp0/sp0.module').then( m => m.Sp0PageModule)
  },
   {
    path: 'sp1',
    loadChildren: () => import('./pages/res/sp-ch/sp1/sp1.module').then( m => m.Sp1PageModule)
  },
  {
    path: 'sp2',
    loadChildren: () => import('./pages/res/sp-ch/sp2/sp2.module').then( m => m.Sp2PageModule)
  },
  {
    path: 'sp3',
    loadChildren: () => import('./pages/res/sp-ch/sp3/sp3.module').then( m => m.Sp3PageModule)
  },
  {
    path: 'sp4',
    loadChildren: () => import('./pages/res/sp-ch/sp4/sp4.module').then( m => m.Sp4PageModule)
  },
  {
    path: 'sp5',
    loadChildren: () => import('./pages/res/sp-ch/sp5/sp5.module').then( m => m.Sp5PageModule)
  },
  {
    path: 'sp6',
    loadChildren: () => import('./pages/res/sp-ch/sp6/sp6.module').then( m => m.Sp6PageModule)
  },
  {
    path: 'sp7',
    loadChildren: () => import('./pages/res/sp-ch/sp7/sp7.module').then( m => m.Sp7PageModule)
  },
  {
    path: 'sp8',
    loadChildren: () => import('./pages/res/sp-ch/sp8/sp8.module').then( m => m.Sp8PageModule)
  },
  {
    path: 'sp9',
    loadChildren: () => import('./pages/res/sp-ch/sp9/sp9.module').then( m => m.Sp9PageModule)
  },
  {
    path: 'sp10',
    loadChildren: () => import('./pages/res/sp-ch/sp10/sp10.module').then( m => m.Sp10PageModule)
  },
  {
    path: 'sp-pdf',
    loadChildren: () => import('./pages/res/sp-ch/sp-pdf/sp-pdf.module').then( m => m.SpPdfPageModule)
  },

  {
    path: 'kr0',
    loadChildren: () => import('./pages/res/kr-ch/kr0/kr0.module').then( m => m.Kr0PageModule)
  },
  {
    path: 'kr1',
    loadChildren: () => import('./pages/res/kr-ch/kr1/kr1.module').then( m => m.Kr1PageModule)
  },
  {
    path: 'kr2',
    loadChildren: () => import('./pages/res/kr-ch/kr2/kr2.module').then( m => m.Kr2PageModule)
  },
  {
    path: 'kr3',
    loadChildren: () => import('./pages/res/kr-ch/kr3/kr3.module').then( m => m.Kr3PageModule)
  },
  {
    path: 'kr4',
    loadChildren: () => import('./pages/res/kr-ch/kr4/kr4.module').then( m => m.Kr4PageModule)
  },
  {
    path: 'kr5',
    loadChildren: () => import('./pages/res/kr-ch/kr5/kr5.module').then( m => m.Kr5PageModule)
  },
  {
    path: 'kr6',
    loadChildren: () => import('./pages/res/kr-ch/kr6/kr6.module').then( m => m.Kr6PageModule)
  },
  {
    path: 'kr7',
    loadChildren: () => import('./pages/res/kr-ch/kr7/kr7.module').then( m => m.Kr7PageModule)
  },
  {
    path: 'kr8',
    loadChildren: () => import('./pages/res/kr-ch/kr8/kr8.module').then( m => m.Kr8PageModule)
  },
  {
    path: 'kr9',
    loadChildren: () => import('./pages/res/kr-ch/kr9/kr9.module').then( m => m.Kr9PageModule)
  },
  {
    path: 'kr10',
    loadChildren: () => import('./pages/res/kr-ch/kr10/kr10.module').then( m => m.Kr10PageModule)
  },
  {
    path: 'kr-pdf',
    loadChildren: () => import('./pages/res/kr-ch/kr-pdf/kr-pdf.module').then( m => m.KrPdfPageModule)
  },

  {
    path: 'sw0',
    loadChildren: () => import('./pages/res/sw-ch/sw0/sw0.module').then( m => m.Sw0PageModule)
  },
  {
    path: 'sw1',
    loadChildren: () => import('./pages/res/sw-ch/sw1/sw1.module').then( m => m.Sw1PageModule)
  },
  {
    path: 'sw2',
    loadChildren: () => import('./pages/res/sw-ch/sw2/sw2.module').then( m => m.Sw2PageModule)
  },
  {
    path: 'sw3',
    loadChildren: () => import('./pages/res/sw-ch/sw3/sw3.module').then( m => m.Sw3PageModule)
  },
  {
    path: 'sw4',
    loadChildren: () => import('./pages/res/sw-ch/sw4/sw4.module').then( m => m.Sw4PageModule)
  },
  {
    path: 'sw5',
    loadChildren: () => import('./pages/res/sw-ch/sw5/sw5.module').then( m => m.Sw5PageModule)
  },
  {
    path: 'sw6',
    loadChildren: () => import('./pages/res/sw-ch/sw6/sw6.module').then( m => m.Sw6PageModule)
  },
  {
    path: 'sw7',
    loadChildren: () => import('./pages/res/sw-ch/sw7/sw7.module').then( m => m.Sw7PageModule)
  },
  {
    path: 'sw8',
    loadChildren: () => import('./pages/res/sw-ch/sw8/sw8.module').then( m => m.Sw8PageModule)
  },
  {
    path: 'sw9',
    loadChildren: () => import('./pages/res/sw-ch/sw9/sw9.module').then( m => m.Sw9PageModule)
  },
  {
    path: 'sw10',
    loadChildren: () => import('./pages/res/sw-ch/sw10/sw10.module').then( m => m.Sw10PageModule)
  },
  {
    path: 'sw-pdf',
    loadChildren: () => import('./pages/res/sw-ch/sw-pdf/sw-pdf.module').then( m => m.SwPdfPageModule)
  }, 
  {
    path: 'ki0',
    loadChildren: () => import('./pages/res/ki-ch/ki0/ki0.module').then( m => m.Ki0PageModule)
  },
  {
    path: 'ki1',
    loadChildren: () => import('./pages/res/ki-ch/ki1/ki1.module').then( m => m.Ki1PageModule)
  },
  {
    path: 'ki2',
    loadChildren: () => import('./pages/res/ki-ch/ki2/ki2.module').then( m => m.Ki2PageModule)
  },
  {
    path: 'ki3',
    loadChildren: () => import('./pages/res/ki-ch/ki3/ki3.module').then( m => m.Ki3PageModule)
  },
  {
    path: 'ki4',
    loadChildren: () => import('./pages/res/ki-ch/ki4/ki4.module').then( m => m.Ki4PageModule)
  },
  {
    path: 'ki5',
    loadChildren: () => import('./pages/res/ki-ch/ki5/ki5.module').then( m => m.Ki5PageModule)
  },
  {
    path: 'ki6',
    loadChildren: () => import('./pages/res/ki-ch/ki6/ki6.module').then( m => m.Ki6PageModule)
  },
  {
    path: 'ki7',
    loadChildren: () => import('./pages/res/ki-ch/ki7/ki7.module').then( m => m.Ki7PageModule)
  },
  {
    path: 'ki8',
    loadChildren: () => import('./pages/res/ki-ch/ki8/ki8.module').then( m => m.Ki8PageModule)
  },
  {
    path: 'ki9',
    loadChildren: () => import('./pages/res/ki-ch/ki9/ki9.module').then( m => m.Ki9PageModule)
  },
  {
    path: 'ki10',
    loadChildren: () => import('./pages/res/ki-ch/ki10/ki10.module').then( m => m.Ki10PageModule)
  },
  {
    path: 'ki-pdf',
    loadChildren: () => import('./pages/res/ki-ch/ki-pdf/ki-pdf.module').then( m => m.KiPdfPageModule)
  }, 
  {
    path: 'olddashboard',
    loadChildren: () => import('./pages/olddashboard/olddashboard.module').then( m => m.OlddashboardPageModule)
  },
  {
    path: 'lkeyone',
    loadChildren: () => import('./pages/res/leadercurriculum/lkeyone/lkeyone.module').then( m => m.LKeyOnePageModule)
  },
  {
    path: 'lkeytwo',
    loadChildren: () => import('./pages/res/leadercurriculum/lkeytwo/lkeytwo.module').then( m => m.LKeyTwoPageModule)
  },
  {
    path: 'lkeythree',
    loadChildren: () => import('./pages/res/leadercurriculum/lkeythree/lkeythree.module').then( m => m.LKeyThreePageModule)
  },
  {
    path: 'lkeyfour',
    loadChildren: () => import('./pages/res/leadercurriculum/lkeyfour/lkeyfour.module').then( m => m.LKeyFourPageModule)
  },
  {
    path: 'lkeyfive',
    loadChildren: () => import('./pages/res/leadercurriculum/lkeyfive/lkeyfive.module').then( m => m.LKeyFivePageModule)
  },
  {
    path: 'mkeyone',
    loadChildren: () => import('./pages/res/movementcurriculum/mkeyone/mkeyone.module').then( m => m.MKeyOnePageModule)
  },
  {
    path: 'mkeytwo',
    loadChildren: () => import('./pages/res/movementcurriculum/mkeytwo/mkeytwo.module').then( m => m.MKeyTwoPageModule)
  }, 
  {
    path: 'mkeythree',
    loadChildren: () => import('./pages/res/movementcurriculum/mkeythree/mkeythree.module').then( m => m.MKeyThreePageModule)
  },
   {
    path: 'mkeyfour',
    loadChildren: () => import('./pages/res/movementcurriculum/mkeyfour/mkeyfour.module').then( m => m.MKeyFourPageModule)
  },
   {
    path: 'mkeyfive',
    loadChildren: () => import('./pages/res/movementcurriculum/mkeyfive/mkeyfive.module').then( m => m.MKeyFivePageModule)
  },
  {
    path: 'fkeyintro',
    loadChildren: () => import('./pages/res/keystofree/fkeyintro/fkeyintro.module').then( m => m.FKeyIntroPageModule)
  },
  {
    path: 'fkeyone',
    loadChildren: () => import('./pages/res/keystofree/fkeyone/fkeyone.module').then( m => m.FKeyOnePageModule)
  },
  {
    path: 'fkeytwo',
    loadChildren: () => import('./pages/res/keystofree/fkeytwo/fkeytwo.module').then( m => m.FKeyTwoPageModule)
  },
  {
    path: 'fkeythree',
    loadChildren: () => import('./pages/res/keystofree/fkeythree/fkeythree.module').then( m => m.FKeyThreePageModule)
  },
  {
    path: 'fkeyfour',
    loadChildren: () => import('./pages/res/keystofree/fkeyfour/fkeyfour.module').then( m => m.FKeyFourPageModule)
  },
  {
    path: 'fkeyfive',
    loadChildren: () => import('./pages/res/keystofree/fkeyfive/fkeyfive.module').then( m => m.FKeyFivePageModule)
  },
  {
    path: 'fkeysix',
    loadChildren: () => import('./pages/res/keystofree/fkeysix/fkeysix.module').then( m => m.FKeySixPageModule)
  },
  {
    path: 'fkeyseven',
    loadChildren: () => import('./pages/res/keystofree/fkeyseven/fkeyseven.module').then( m => m.FKeySevenPageModule)
  },
  {
    path: 'fkeyeight',
    loadChildren: () => import('./pages/res/keystofree/fkeyeight/fkeyeight.module').then( m => m.FKeyEightPageModule)
  },
  {
    path: 'fkeynine',
    loadChildren: () => import('./pages/res/keystofree/fkeynine/fkeynine.module').then( m => m.FkeyninePageModule)
  },
  {
    path: 'fkeyten',
    loadChildren: () => import('./pages/res/keystofree/fkeyten/fkeyten.module').then( m => m.FkeytenPageModule)
  },
  {
    path: 'fkeyele',
    loadChildren: () => import('./pages/res/keystofree/fkeyele/fkeyele.module').then( m => m.FkeyelePageModule)
  },
  {
    path: 'fkeypdf',
    loadChildren: () => import('./pages/res/keystofree/fkeypdf/fkeypdf.module').then( m => m.FkeypdfPageModule)
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading,onSameUrlNavigation:'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
