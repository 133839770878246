import { ApiService } from 'src/app/services/api.service-new';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams,   } from '@ionic/angular';
import { Router } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-commingsoon',
  templateUrl: './commingsoon.component.html',
  styleUrls: ['./commingsoon.component.scss'],
})
export class CommingSoonModelComponent  {
  MASSAGE='';
  
  UserSelectedValue=[];
  @ViewChild('rating') rating : any;

  constructor(private ngxService: NgxUiLoaderService,public navParams: NavParams, public router: Router, private apiService: ApiService, public tools: Tools, public modalCtrl: ModalController) {
    console.log('massage b>>>',this.navParams.get("MASSAGE"));
    this.MASSAGE = this.navParams.get("MASSAGE");
    console.log('massage a>>>',this.MASSAGE);

   
  }

  ionViewWillEnter() {
  }
 
  dismissModal() {
    this.modalCtrl.dismiss('');
  }
  close() {
    this.modalCtrl.dismiss('');
  }

}
