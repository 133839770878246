import { ApiService } from 'src/app/services/api.service-new';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams,   } from '@ionic/angular';
import { Router } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-sharepopup',
  templateUrl: './sharepopup.component.html',
  styleUrls: ['./sharepopup.component.scss'],
})
export class SharePopupModelComponent implements OnInit {
  SUserID='';
  SFROM='';

  UserSelectedValue=[];
  @ViewChild('rating') rating : any;

  guestlist = [];
  autoguestnew = [];
  disciple_Select:any;

  constructor(private ngxService: NgxUiLoaderService,public navParams: NavParams, public router: Router, private apiService: ApiService, public tools: Tools, public modalCtrl: ModalController) {
    this.SUserID = this.navParams.get("SUserID");
    this.SFROM = this.navParams.get("SFROM");
   
  }
  ngOnInit(){
    // Get all share buttons
    const shareButtons = document.querySelectorAll('.share-button');
    // Add click event listener to each button
    shareButtons.forEach(button => {
       button.addEventListener('click', () => {
          // Get the URL of the current page
          const url = window.location.href;
          // Get the social media platform from the button's class name
          const platform = button.classList[1];
          // Set the URL to share based on the social media platform
          let shareUrl;
          switch (platform) {
             case 'facebook':
             shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}"`;
             break;
             case 'twitter':
             shareUrl = `https://twitter.com/share?url=${encodeURIComponent(url)}`;
             break;
             case 'whatsapp':
             shareUrl = `https://api.whatsapp.com/send?text=Build_your_custom`;
             break;
          }
          // Open a new window to share the URL
          window.open(shareUrl, '_blank');
       });
    });
  }

  ionViewWillEnter() {
   // this.GameJoinUserValue();
   this.GetDiscipleList('ALL');

  }
  GetDiscipleList(key) {
    if (this.tools.isNetwork()) {
      this.ngxService.startLoader("loader-01");
      let postData = new FormData();
      postData.append('FilterKey', key);
      this.apiService.GetDiscipleList(postData).subscribe(
        (data) => {
          let res: any = data;
          this.ngxService.stopLoader('loader-01');
          console.log(' GetDiscipleList > ', res.data.Disciple);
          this.guestlist = res.data.Disciple;
          for (let index = 0; index < this.guestlist.length; index++) {
            const element = this.guestlist[index];
            this.autoguestnew.push({name: element.CustomerName,id: element.DescipleID});
          }
          console.log(this.autoguestnew);
        },
        (error: Response) => {
          this.ngxService.stopLoader('loader-01');
          console.log(error);
          let err: any = error;
          this.tools.openAlertToken(err.status, err.error.message);
        }
      );
    } else {
      this.ngxService.stopLoader('loader-01');
    }
  }

  discipleSelect(ev){
    // console.log("multi dis >>>>",ev)
    console.log("multi dis >>>>",this.disciple_Select)
    console.log("multi dis >>>>",this.disciple_Select.join(', '))
  }

  dismissModal() {
    this.modalCtrl.dismiss('');
  }
  close() {
    this.modalCtrl.dismiss('');
  }

  Share(){
    if (this.disciple_Select == undefined) {
      this.tools.openAlert('Please Select  Disciples.');
    }else{
      console.log("multi dis >>>>",this.disciple_Select)
      this.ShareDiscipleList();
    }
  }

   ShareDiscipleList() {
    if (this.tools.isNetwork()) {
      this.ngxService.startLoader("loader-01");
      let postData = new FormData();
      postData.append('ShareProfileID', this.SUserID);
      postData.append('WithShareUserID', this.disciple_Select);
      postData.append('ShareFrom', this.SFROM);
      this.apiService.ShareDiscipleList(postData).subscribe(
        (data) => {
          this.ngxService.stopLoader('loader-01');
          let res: any = data;
          this.modalCtrl.dismiss('');
          console.log(' GetDiscipleList > ', res.data);
        },
        (error: Response) => {
          this.ngxService.stopLoader('loader-01');
          console.log(error);
          let err: any = error;
          this.tools.openAlertToken(err.status, err.error.message);
        }
      );
    } else {
      this.ngxService.stopLoader('loader-01');
    }
  }
}
