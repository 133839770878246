// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.holds-the-iframe {
  background: url('bx_loader.ea463d0140712ed3.gif') center center no-repeat;
}`, "",{"version":3,"sources":["webpack://./src/app/model/videopopup/videopopup.component.scss"],"names":[],"mappings":"AAAA;EACI,yEAAA;AACJ","sourcesContent":[".holds-the-iframe {\n    background: url(../../../assets/images/bx_loader.gif) center center no-repeat;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
