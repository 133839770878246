export const environment = {
  production: true,
  // BaseUrl: 'https://clientsdemoarea.com/projects/natural_discipleship/api/v1/',
  BaseUrl: 'https://naturaldiscipleship.com/api/v1/',
  username: 'natural',
  password:'natural@123',
  apikey:'ow400c888s4c8wck8w0c0w8co0kc00o0wgoosw80',
  recaptcha:{
    siteKey:"6LcDZk0gAAAAAOJR81pk8n8WZm4XqrjRnne5gjJk",
  }
};