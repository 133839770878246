import { ApiService } from 'src/app/services/api.service-new';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams,   } from '@ionic/angular';
import { Router } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-msgpopup',
  templateUrl: './msgpopup.component.html',
  styleUrls: ['./msgpopup.component.scss'],
})
export class MSGPopupModelComponent  {
  ScriptID='';
  GameID='';
  GamePriceID='';

  UserSelectedValue=[];
  @ViewChild('rating') rating : any;

  constructor(private ngxService: NgxUiLoaderService,public navParams: NavParams, public router: Router, private apiService: ApiService, public tools: Tools, public modalCtrl: ModalController) {
    this.ScriptID = this.navParams.get("ScriptID");
  
  }

  ionViewWillEnter() {
   // this.GameJoinUserValue();
  }
  

  dismissModal() {
    this.modalCtrl.dismiss('');
  }
  close() {
    this.modalCtrl.dismiss('');
  }

}
