import { ApiService } from 'src/app/services/api.service-new';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams,   } from '@ionic/angular';
import { Router } from '@angular/router';
import { Tools } from 'src/app/shared/tools';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PaypalPopupModelComponent } from '../paypalpopup/paypalpopup.component';
@Component({
  selector: 'app-registerpop',
  templateUrl: './registerpop.component.html',
  styleUrls: ['./registerpop.component.scss'],
})
export class RegisterPopModelComponent  {
  registerForm: FormGroup;
  showPassword = false;
  passwordToggleicon = "fas fa-eye pass";

  CountryList = [];
  CountryID = '';

  StatesList = [];
  StatesID = '';

  LanguageList = [];
  LanguageID = '';

  InvitedList = [];
  InvitedID = '';
  InvitedName = '';

  OrganizationList = [];
  OrganizationID = '';
  OrganizationName = '';

   //For Autocomplete
   public Orgresults = [...this.OrganizationList];
   public Invresults = [...this.InvitedList];
 
   //For Plan
   PlanID='';
   paymentAmount:any;

  constructor(private ngxService: NgxUiLoaderService,public navParams: NavParams,public formBuilder: FormBuilder, public router: Router, private apiService: ApiService, public tools: Tools, public modalCtrl: ModalController) {
    this.PlanID = this.navParams.get("PlanID");
    this.paymentAmount = this.navParams.get("AMT");

    console.log("plan id ###",this.PlanID)
    console.log("paymentAmount>>>",this.paymentAmount)

    this.registerForm = this.formBuilder.group({
      fname: ['', [Validators.required,Validators.pattern('(.{3,})')]],
      lname: ['', [Validators.required,Validators.pattern('(.{3,})')]],
      mobile: ['', [Validators.required]],
      TC: ['false', [Validators.requiredTrue]],
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      City: ['', [Validators.required,Validators.pattern('(.{3,})')]],
    });
  }
  test(){
    console.log("test")
  }
  ionViewWillEnter() {
    this.getCountryList();
  }
  InvitedHandleChange(Key) {
    if (Key == undefined || Key == "") {
      this.Invresults=[];
    } else {
      const query = Key.toLowerCase();
      this.Invresults = this.InvitedList.filter(d => d.username.toLowerCase().indexOf(query) > -1);
    }
  }
  InvitedSelect(id,name) {
    this.InvitedID = id;
    this.InvitedName = name;
    this.Invresults = []
  }

  ORGHandleChange(Key) {
    if (Key == undefined || Key == "") {
      this.Orgresults=[];
    } else {
      const query = Key.toLowerCase();
      this.Orgresults = this.OrganizationList.filter(d => d.ChurchName.toLowerCase().indexOf(query) > -1);
    }
  }
  ORGSelect(id,name) {
    this.OrganizationID = id;
    this.OrganizationName = name;
    this.Orgresults = []
  }

  onChangeState(scripName) {
    this.CountryID = scripName.target.value;
    console.log("a>>", this.CountryID);
    this.GetStateList();
  }

  getCountryList() {
    if (this.tools.isNetwork()) {
      // this.tools.openLoader();
      this.ngxService.startLoader("loader-01");
      this.apiService.GetCountryList().subscribe(data => {
        // this.tools.closeLoader();
        this.getLanguageList();

        let res: any = data;
        console.log(' countrylist > ', res);
        this.CountryList = res.data.Countries;

      }, (error: Response) => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
        console.log(error);

        let err: any = error;
        this.tools.openAlertToken(err.status, err.error.message);
      });

    } else {
      // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
    }

  }

  GetStateList() {
    if (this.tools.isNetwork()) {
      let postData = new FormData();
      postData.append('id', this.CountryID);

      // this.tools.openLoader();
      this.ngxService.startLoader("loader-01");
      this.apiService.GetStateList(postData).subscribe(data => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");

        let res: any = data;
        console.log(' StatesList > ', res);
        this.StatesList = res.data.states;

      }, (error: Response) => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
        console.log(error);

        let err: any = error;
        this.tools.openAlertToken(err.status, err.error.message);
      });

    } else {
      // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
    }

  }
  getLanguageList() {
    if (this.tools.isNetwork()) {
      //this.tools.openLoader();
      this.apiService.GetLanguageList().subscribe(data => {
        // this.tools.closeLoader();
        this.getInvitedList()
        let res: any = data;
        console.log(' countrylist > ', res);
        this.LanguageList = res.data.Language;

      }, (error: Response) => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
        console.log(error);

        let err: any = error;
        this.tools.openAlertToken(err.status, err.error.message);
      });

    } else {
      // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
    }

  }
  getInvitedList() {
    if (this.tools.isNetwork()) {
      //this.tools.openLoader();
      this.apiService.getInvitedList().subscribe(data => {
        // this.tools.closeLoader();
        this.getOrganizationList();
        let res: any = data;
        console.log(' countrInvitedListylist > ', res);
        this.InvitedList = res.data.invitedby;

      }, (error: Response) => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
        console.log(error);

        let err: any = error;
        this.tools.openAlertToken(err.status, err.error.message);
      });

    } else {
      // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
    }

  }
  getOrganizationList() {
    if (this.tools.isNetwork()) {
      //this.tools.openLoader();
      this.apiService.getOrganizationList().subscribe(data => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");

        let res: any = data;
        console.log(' countrylist > ', res);
        this.OrganizationList = [];

        for (let index = 0; index < res.data.Organization.length; index++) {
          const element = res.data.Organization[index];
          if(element.ChurchName != 'None' && element.short != undefined){
            element.FullName = element.ChurchName +'('+ element.short +'-'+element.City +')';
          }else{
            element.FullName = element.ChurchName;
          }
          this.OrganizationList.push(element)

        }


        // this.OrganizationList = res.data.Organization;

      }, (error: Response) => {
        // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
        console.log(error);

        let err: any = error;
        this.tools.openAlertToken(err.status, err.error.message);
      });

    } else {
      // this.tools.closeLoader();
        this.ngxService.stopLoader("loader-01");
    }

  }
async register() {
    this.modalCtrl.dismiss()
    this.SelectedPlan()

    // console.log(this.CountryID)
    // let fname = this.registerForm.get('fname').value;
    // let lname = this.registerForm.get('lname').value;
    // let mobile = this.registerForm.get('mobile').value;
    // let email = this.registerForm.get('email').value;
    // let password = this.registerForm.get('password').value;
    // let City = this.registerForm.get('City').value;

    // var msg = ''
    // if (fname == "") {
    //   msg = msg + "Enter your first name<br />";
    // }
    // if (lname == "") {
    //   msg = msg + "Enter your last name<br />";
    // }
    // if (mobile == "") {
    //   msg = msg + "Enter your mobile  No<br />";
    // }
    // // if (this.registerForm.get('mobile').invalid) {
    // //   if (mobile == '') {
    // //     msg = msg + 'Enter your mobile No.<br />'
    // //   } else {
    // //     msg = msg + 'Please enter a valid mobile No.<br />'
    // //   }
    // // } 
    // if (this.registerForm.get('email').invalid) {
    //   if (email == '') {
    //     msg = msg + 'Enter your Email ID<br />'
    //   } else {
    //     msg = msg + 'Please enter a valid Email ID<br />'
    //   }
    // }

    // if (password == "") {
    //   msg = msg + "Enter your Password<br />";
    // }

    // if (this.CountryID == "") {
    //   msg = msg + "Select Country<br />";
    // }
    // if (this.StatesID == "") {
    //   msg = msg + "Select State<br />";
    // }

    // if (City == "") {
    //   msg = msg + "Enter your City/Province<br />";
    // }

    // // if (this.InvitedID == "") {
    // //   msg = msg + "Select Invited By<br />";
    // // }
    // if (this.OrganizationID == "") {
    //   msg = msg + "Select Church/Organization<br />";
    // }
    // if (this.LanguageID == "") {
    //   msg = msg + "Select Language<br />";
    // }



    // if (msg != '') {
    //   this.tools.openAlert(msg);
    // } else {
    //   var result_network = await this.tools.isNetwork();
    //   console.log('Network login >>>',result_network);

    //   // if (result_network) {
    //     // this.tools.openLoader();
    //   this.ngxService.startLoader("loader-01");
    //     this.apiService.UserRegister(fname, lname, mobile, email, password, this.CountryID, this.StatesID, City, this.InvitedID, this.OrganizationID, this.LanguageID).subscribe(response => {
    //       // this.tools.closeLoader();
    //     this.ngxService.stopLoader("loader-01");
    //       let res: any = response;
    //       console.log("Res ::: ", res)

    //       this.registerForm.reset();
    //       localStorage.setItem('login_token', res.login_token);
    //       localStorage.setItem('isLogin', 'true');
    //       this.apiService.setUserData(res.data.user, res.login_token);
    //       this.apiService.setPlanData(res.data.planDetail);
    //       // this.router.navigateByUrl('/dashboard', { replaceUrl: true });
    //       this.apiService.ShowUpdateProfil("1");
    //       this.tools.OpenAlertForNavigate(res.message,'dashboard');
          
    //     }, (error: Response) => {
    //       // this.tools.closeLoader();
    //     this.ngxService.stopLoader("loader-01");
    //       let err: any = error;
    //       console.log('Api Error ', err);
    //       this.tools.openAlertToken(err.status, err.error.message);

    //     });
    //   // } else {
    //   //   console.log('Network else');
    //   //   // this.tools.closeLoader();
    //   // }
    // }
  }
  
  togglePassword(): void {
    this.showPassword = !this.showPassword;
    if (this.passwordToggleicon == 'fas fa-eye pass') {
      this.passwordToggleicon = 'fas fa-eye-slash pass';
    } else {
      this.passwordToggleicon = 'fas fa-eye pass';
    }
  }


  dismissModal() {
    this.modalCtrl.dismiss();
  }
  close() {
    this.modalCtrl.dismiss('');
  }


  async SelectedPlan() {
      const modal = await this.modalCtrl.create({
        component: PaypalPopupModelComponent,
        cssClass: 'PayPal-Sel-modal',
        componentProps: { PlanID: this.PlanID, AMT: this.paymentAmount },
        backdropDismiss: false

      });
      await modal.present();
      await modal.onDidDismiss()
        .then((data) => {
          console.log('Selected Cart Items from Dilogs ', data);
          if (data) {
            // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
            //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
          }
        });
  }

}
