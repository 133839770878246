import { ToastController, LoadingController, AlertController, NavController, Platform, MenuController, ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service-new';
import { GeneralPopupModelComponent } from '../model/generalpopup/generalpopup.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VideoPopupModelComponent } from '../model/videopopup/videopopup.component';

@Injectable()
export class Tools {
  openLoader() {
    throw new Error('Method not implemented.');
  }
  closeLoader() {
    throw new Error('Method not implemented.');
  }
    expMonth = [];
    expYear = [];
    cartType = ["Visa", "MasterCard", "American Express", "Diners"];
    notification;
    loading;
    CountryCodes = [];
    CountryCodes_0 = [];

    alert:any;
    //bookmark
    BookMarkList: any = []
    constructor(private ngxService: NgxUiLoaderService,private menu: MenuController, 
        public alertController: AlertController, private apiServices: ApiService,
        public toastController: ToastController, private platform: Platform,public modalCtrl: ModalController,
        public loadingController: LoadingController, public router: Router, private navCtrl: NavController,
    ) {
        this.expMonth = [];
        this.expMonth.push({ key: 'January', val: '01' })
        this.expMonth.push({ key: 'February', val: '02' })
        this.expMonth.push({ key: 'March', val: '03' })
        this.expMonth.push({ key: 'April', val: '04' })
        this.expMonth.push({ key: 'May', val: '05' })
        this.expMonth.push({ key: 'June', val: '06' })
        this.expMonth.push({ key: 'July', val: '07' })
        this.expMonth.push({ key: 'August', val: '08' })
        this.expMonth.push({ key: 'September', val: '09' })
        this.expMonth.push({ key: 'October', val: '10' })
        this.expMonth.push({ key: 'November', val: '11' })
        this.expMonth.push({ key: 'December', val: '12' })
        this.expYear = [];
        var ctYear = new Date().getFullYear().toString();
        for (let i = 0; i < 15; i++) {
            this.expYear.push((parseInt(ctYear) + i));
        }
        // console.log("expYear ",this.expYear)
        //   this.CountryCodes=[{"name":"Jersey","flag":"🇯🇪","code":"JE","dial_code":"+44"}] 
      

    }

    backPage() {
        this.navCtrl.back();
    }
    convertVal(calVal) {
        // console.log('calValue ',calVal);
        if (calVal != undefined) {
            return parseFloat(calVal).toFixed(2);
        } else {
            return 0.00;
        }
    }
  async isNetwork() {
        
    // const status =  await Network.getStatus();
          
    //     console.log('Network >>>', status.connectionType);
    //     if (status.connectionType == 'none') {
    //         this.closeLoader();
    //         this.loadingController.getTop().then(hasLoading => {
    //           if (hasLoading) {
    //               this.loadingController.dismiss();
    //           }
    //       });
    //         this.presentAlert('No internet', 'You do not have an Internet connection. Please check your connection status', 'Ok')
    //         return false;
    //     } else {
    //         return true;
    //     }
        
        // Network.addListener('networkStatusChange', status => {
        //     console.log('Network_status_changed>>>', status);

        //             if (status.connectionType == 'none') {
        //                 this.closeLoader();
        //                 this.presentAlert('No internet', 'You do not have an Internet connection. Please check your connection status', 'Ok')
        //                 return false;
        //             } else {
        //                 return true;
        //             }
        //   });
         
       
        // FOR CORDOVA
        // console.log('Network Type>> ', this.network.type);
        // if (this.network.type == 'none') {
        //     this.closeLoader();
        //     this.presentAlert('No internet', 'You do not have an Internet connection. Please check your connection status', 'Ok')
        //     return false;
        // } else {
        //     return true;
        // }
    }


    formatePhone(phoneNumber) {
        let phoneString = phoneNumber.replace(/[^a-zA-Z0-9]/g, '');
        return phoneString.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }


    async openNotification(msg) {
        this.notification = await this.toastController.create({
            message: msg,
            duration: 3000,
            position: 'bottom',
            cssClass: 'info'
        });
        this.notification.present();
    }

    //OLD ONE
//     savebookmark(POS, NAME, LocalName) {
//       console.log("data.pos >>> ", POS)
//       console.log("data.name >>> ", NAME)
//       console.log("this.BookMarkList.length", this.BookMarkList.length)
//       var exists = 0
//       if (this.BookMarkList.length > 0) {
//           this.BookMarkList.forEach(async element => {
//               if (NAME == element.Name) {
//                   // this.openNotification("This name is alreay exists")
//                   exists = 1;
//                   const alert = await this.alertController.create({
//                       message: "Do you want to replace your previously saved marker for this person?",
//                       buttons: [
//                           {
//                               text: 'Cancel',
//                               role: 'cancel',
//                               handler: () => {

//                               }
//                           },
//                           {
//                               text: 'Yes',
//                               handler: () => {

//                                   element.pos = POS;
//                                   console.log("this.BookMarkList >>> ", this.BookMarkList)
//                                   localStorage.setItem(LocalName, JSON.stringify(this.BookMarkList))
//                                   console.log("alaert yes >>> ")
//                               }
//                           }
//                       ], backdropDismiss: true
//                   });
//                   await alert.present();
//               }
//           });
//           if (exists == 0) {
//               this.BookMarkList.push({ "pos": POS, "Name": NAME });
//               localStorage.setItem(LocalName, JSON.stringify(this.BookMarkList))
//               this.openNotification("Bookmark Saved for " + NAME)
//           }
//       } else {
//           this.BookMarkList.push({ "pos": POS, "Name": NAME });
//           localStorage.setItem(LocalName, JSON.stringify(this.BookMarkList))
//           this.openNotification("Bookmark Saved for " + NAME)
//       }
//       return this.BookMarkList;
//   }

  savebookmark(BookMarkList,POS, NAME, LocalName) {
    this.BookMarkList=BookMarkList;
    console.log("data.pos >>> ", POS)
    console.log("data.name >>> ", NAME)
    console.log("this.BookMarkList.length", this.BookMarkList.length)
    var exists = 0
    if (this.BookMarkList.length > 0) {
        this.BookMarkList.forEach(async element => {
            if (NAME == element.Name) {
                // this.openNotification("This name is alreay exists")
                exists = 1;
                const alert = await this.alertController.create({
                    message: "Do you want to replace your previously saved marker for this person?",
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {

                            }
                        },
                        {
                            text: 'Yes',
                            handler: () => {

                                element.pos = POS;
                                console.log("this.BookMarkList >>> ", this.BookMarkList)
                                localStorage.setItem(LocalName, JSON.stringify(this.BookMarkList))
                                console.log("alaert yes >>> ")
                            }
                        }
                    ], backdropDismiss: true
                });
                await alert.present();
            }
        });
        if (exists == 0) {
            this.BookMarkList.push({ "pos": POS, "Name": NAME });
            localStorage.setItem(LocalName, JSON.stringify(this.BookMarkList))
            this.openNotification("Bookmark Saved for " + NAME)
        }
    } else {
        this.BookMarkList.push({ "pos": POS, "Name": NAME });
        localStorage.setItem(LocalName, JSON.stringify(this.BookMarkList))
        this.openNotification("Bookmark Saved for " + NAME)
    }
    return this.BookMarkList;
}

    async formateDateyyyymmddhis(date) {
        // tslint:disable-next-line:prefer-const
        let mm = ('0' + (date.getMonth() + 1)).slice(-2);
        // tslint:disable-next-line:prefer-const
        let dd = ('0' + (date.getDate())).slice(-2);
        // tslint:disable-next-line:prefer-const
        let yyyy = date.getFullYear();
        // tslint:disable-next-line:prefer-const
        let h = ('0' + (date.getHours())).slice(-2);
        // tslint:disable-next-line:prefer-const
        let i = ('0' + (date.getMinutes())).slice(-2);
        // tslint:disable-next-line:prefer-const
        let s = ('0' + (date.getSeconds())).slice(-2);
        // tslint:disable-next-line:prefer-const
        let today = yyyy + '-' + mm + '-' + dd + ' ' + h + ':' + i + ':' + s;
        return today;
    }

    async formateDay(today) {
        let dd = today.getDate();
        // tslint:disable-next-line:prefer-const
        let yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // January is 0!

        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    async formatAMPM1(date) {
        // console.log(date);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        // tslint:disable-next-line:prefer-const
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        // tslint:disable-next-line:prefer-const
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    async formatAMPM(date) {
        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes();
        // tslint:disable-next-line:prefer-const
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        // tslint:disable-next-line:prefer-const
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    // async openLoader(cssClass = '', msg?) {
    //     // console.log(' ===> ',msg);
    //     this.loading = await this.loadingController.create({
    //         message: msg ? msg : 'Please wait...',
    //         keyboardClose: true,
    //         showBackdrop: true,
    //         cssClass: cssClass
    //     });
    //     await this.loading.present();
    //     await this.loading.present();
    //     setTimeout(() => {
    //         if (this.loading) {
    //             this.loading.dismiss();
    //         }
    //     }, 8000);
    // }
    // async openLoader(msg?, time?) {
    //     // this.loading = await this.loadingController.create({
    //     //     message: '', //,msg ? msg : 'Please wait...',
    //     //     spinner: null,
    //     //     keyboardClose: true,
    //     //     showBackdrop: true,
    //     //     backdropDismiss: false,
    //     //     // cssClass: cssClass
    //     //     cssClass: 'custom-loading'
    //     // });

    //     // await this.loading.present();
    // }
    async openNetworkAlert() {
        this.alert = await this.alertController.create({
            message: '<img src="../../../assets/images/netlost.png" alt="g-maps" style="border-radius: 2px">',
            backdropDismiss: false
        });
        return await this.alert.present();
    }
    async closeNetworkAlert() {
        return await this.alert.dismiss();
    }

    async OpenAlertForNavigate(message,Navigate) {
        const alert = await this.alertController.create({
            message: message ? message : 'Natural Discipleship',
            buttons: [
                {
                    text:'OK',
                    handler: () => {
                            this.router.navigateByUrl('/'+Navigate, { replaceUrl: true });
                    }
                }
            ],
            backdropDismiss: false
        });
        return await alert.present();
    }
    async openAlert(message) {
        const alert = await this.alertController.create({
            message: message ? message : 'oops! something went wrong',
            buttons: ['OK'],
            backdropDismiss: false
        });
        return await alert.present();
    }
    async openAlertToken(status, message) {
        console.log('Api Status ', status)
        const alert = await this.alertController.create({
            message: message ? message : 'oops! something went wrong',
            buttons: [
                {
                    text: status == 401 ? 'Login' : 'Ok',
                    handler: () => {
                        if (status == 401) {
                    
                            localStorage.clear();
                            localStorage.removeItem('killtechno_cart_data');
                            localStorage.removeItem('user_id');
                            localStorage.removeItem('login_token');
                            localStorage.removeItem('killtechno_user_data');
                            this.router.navigateByUrl('/login', { replaceUrl: true });
                        }
                    }
                }
            ],
            backdropDismiss: false
        });
        return await alert.present();
    }

    async presentLogout(message, btnYes, btnNo) {
        const alert = await this.alertController.create({
            message: message,
            buttons: [
                {
                    text: btnNo ? btnNo : 'Cancel',
                    role: 'cancel',
                    handler: () => {

                    }
                },
                {
                    text: btnYes ? btnYes : 'Yes',
                    handler: () => {
                        localStorage.clear();
                        localStorage.removeItem('killtechno_cart_data');
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('login_token');
                        localStorage.removeItem('killtechno_user_data');

                        this.router.navigateByUrl('/login', { replaceUrl: true });
                    }
                }
            ], backdropDismiss: true
        });
        return await alert.present();
    }
    async presentAlertToLogin(title, msg, btnOk, page?) {
        // this.closeLoader();
        this.ngxService.stopLoader("loader-01");
        const alert = await this.alertController.create({
            header: title,
            message: msg,
            backdropDismiss: false,
            buttons: [
                {
                    text: btnOk,
                    handler: () => {

                        localStorage.clear();
                        localStorage.removeItem('killtechno_cart_data');
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('login_token');
                        localStorage.removeItem('killtechno_user_data');
                        this.router.navigateByUrl('/login', { replaceUrl: true });
                    }
                }
            ]
        });
        await alert.present();
    }

    async presentAlert(title, msg, btnOk?, isMove?, cancel?) {
        const alert = await this.alertController.create({
            header: title,
            message: msg,
            backdropDismiss: cancel != undefined ? false : true,
            buttons: [
                {
                    text: btnOk != undefined ? btnOk : 'Ok',
                    handler: () => {
                        if (isMove) {
                            this.router.navigateByUrl('/dashboard', { replaceUrl: true });
                        } else if (btnOk == 'update') {
                            //https://play.google.com/store/apps/details?id=com.eshci.hungryplace&rdid=com.eshci.hungryplace
                            if (this.platform.is('android')) {
                                window.open('market://details?id=com.cuisine.je', '_system');
                            } else if (this.platform.is('ios')) {
                                window.open('itms-apps://itunes.apple.com/app/id1309218017', '_system');
                            }
                        }
                    }
                }
            ]
        });
        await alert.present();
    }

    async presentConfirm(message, btnYes, btnNo) {
        const alert = await this.alertController.create({
            message: message ? message : 'Do you want to buy this book?',
            buttons: [
                {
                    text: btnNo ? btnNo : 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        // console.log('Cancel clicked');
                    }
                },
                {
                    text: btnYes ? btnYes : 'Yes',
                    handler: () => {
                        // console.log('Buy clicked');
                    }
                }
            ], backdropDismiss: false
        });
        return await alert.present();
    }
    // async closeLoader() {
    //     // if (this.loading) {
    //     //     this.loading.dismiss()
    //     // }
    //     // // while (await this.loadingController.getTop() !== undefined) {
    //     // //     await this.loadingController.dismiss();
    //     // //   }

    //     // if (this.loading)
    //     //     this.loading.dismiss()
    //     //         .then(() => {
    //     //             this.loading = null;
    //     //         })
    //     //         .catch(e => console.log('Loader ', e));
    // }
    
    //     logout(){

    //             if (this.isNetwork()) {
    //               this.openLoader();
    //               this.apiServices.logout().subscribe(response => {
    //                 this.closeLoader();
    //                 let res: any = response;
    //                 if (res.code == 1) {
    //                     localStorage.setItem('cui-data','');
    //                     localStorage.setItem('login_token','');
    //                     localStorage.clear();

    //                     this.router.navigateByUrl('/login/home', { replaceUrl: true });
    //                   console.log('Api  ', res.details);
    //                 } else if (res.code == 2) {
    //                   this.presentAlert('', res.msg);
    //                 }

    //               }, (error: Response) => {
    //                 this.closeLoader();
    //                 let err: any = error;        
    //                 console.log('Api Error ', err);
    //               });
    //             } else {
    //               this.closeLoader();
    //             }
    //    }
    // dataURItoBlob(dataURI) {
    //     // convert base64/URLEncoded data component to raw binary data held in a string
    //     let byteString;
    //     if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    //         byteString = atob(dataURI.split(',')[1]);
    //     } else {
    //         byteString = unescape(dataURI.split(',')[1]);
    //     }
    //     // separate out the mime component
    //     // tslint:disable-next-line:prefer-const
    //     let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    //     // write the bytes of the string to a typed array
    //     // tslint:disable-next-line:prefer-const
    //     let ia = new Uint8Array(byteString.length);
    //     for (let i = 0; i < byteString.length; i++) {
    //         ia[i] = byteString.charCodeAt(i);
    //     }
    //     return new Blob([ia], {
    //         type: mimeString
    //     });
    // }
    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/jpeg' });
        return blob;
      }
    returnImageName(keys, file, blob) {
        let imageName;
        if (blob) {
            imageName = new Date().getTime() + keys + '.jpg';
            blob.lastModified = new Date();
            blob.name = imageName;
        } else {
            imageName = new Date().getTime() + keys + file.name;
        }
        imageName.replace(/_/g, ' ');
        imageName = imageName.replace(/\s+/g, '-');
        imageName = imageName.replace(/%20/g, '_');
        return imageName;
    }

    checkScreen() {
        let innerWidth = window.innerWidth;
        console.log('Inner Width ',innerWidth);
        switch (true) {
          case 340 <= innerWidth && innerWidth <= 400:
            return 1;
          case 401 <= innerWidth && innerWidth <= 700:
            return 2;
          case 701 <= innerWidth && innerWidth <= 900:
            return 3;
          case 901 <= innerWidth:
            return 4;
        }
      }

        scrollTo(elementId:string) {
            let todayItem = document.getElementById(elementId);
            todayItem.scrollIntoView(true);
            this.menuClose();
            // this.menu.enable(false); 
            // this.content.scrollTo(0, todayItem.offsetTop, 1000);
  }

  menuClose() {
    if(this.checkScreen() != 4)
    this.menu.close();
  }

  goSchemeGroup(){
    this.router.navigateByUrl('/login',{replaceUrl:true});
   // this.menuClose();
   }
   goToHome(){
    this.router.navigateByUrl('/dashboard',{replaceUrl:true});
   // this.menuClose();
   }
   goToChurch(){
    this.router.navigateByUrl('churchlogin');
  }
  goToPrayer(){
    this.router.navigateByUrl('prayer');
  }
  goToLogin(){
    this.router.navigateByUrl('login');
  }
  goToFAQ(){
    this.router.navigateByUrl('faq');
  }
  goToResources(){
    this.router.navigateByUrl('resources');
  }
  goToResourcesDetails(PlanID){
    this.router.navigateByUrl('resourcesdetail/'+PlanID);
  }
  goToLeadershipTeam(){
    this.router.navigateByUrl('leadershipteam');
  }
  goToMoment(){
    this.router.navigateByUrl('movementview');
  }
  goToDisciple(){
    this.router.navigateByUrl('disciplelist');
  }
  goToDiscipleDetails(DescipleID,FromDeciple){
    this.router.navigateByUrl('disciplelistdetail/'+DescipleID+'/'+FromDeciple);
  }
  goToTheQue(){
    this.router.navigateByUrl('theque');
  }
  goHowTo(){
    this.router.navigateByUrl('howto');
  }
  goToCalendar(){
    this.router.navigateByUrl('calendar');
  }
  goToPP(){
    this.router.navigateByUrl('privacypolicy');
  }
  goToTC(){
    this.router.navigateByUrl('termscondi');
  }
  goToAbout(){
    this.router.navigateByUrl('about');
  }
  goToContactUs(){
    this.router.navigateByUrl('contactus');
  }
  goToForgotPass(){
    this.router.navigateByUrl('forgotpassword');
  }
  goToDonate(){
    // this.router.navigateByUrl('donate');
    this.router.navigateByUrl('/donate', { replaceUrl: true });

  }
  goToChangePassword(){
    this.router.navigateByUrl('changepassword');
  }
  goToPrayerList(){
    this.router.navigateByUrl('prayerlist');
  }
  goToProfile(){
    this.router.navigateByUrl('myprofile');
  }
  goToSubscription(){
    this.router.navigateByUrl('subscription');
  }
  goToNotification(){
    this.router.navigateByUrl('notificationsetting');
  }
  goToBadgeDetail(FromHome){
    this.router.navigateByUrl('badgedetail/'+FromHome);
  }
  goToMetrics(){
    this.router.navigateByUrl('metrics');
  }
  goToStories(){
    this.router.navigateByUrl('storiesdetails');
  }

  async openCommingPopUp(Massage){
    console.log('massage tools>>>',Massage);

    const modal = await this.modalCtrl.create({
        component: GeneralPopupModelComponent,
        cssClass: 'msg-modal',
        componentProps: {MASSAGE: Massage},
    
      });
      await modal.present();
      await modal.onDidDismiss()
        .then((data) => {
        //   console.log('Selected Cart Items from Dilogs ',data);
        //   if (data) {
        //     // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
        //     //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
        //   }
        });
    
  }

   async v0() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/mkdNWHd3aAs" },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }
    async v1() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/H1GTxlopKM8" },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }
    async v2() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/wtXqtx6a7uE" },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }
    async v3() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/T2LbvK8_fNE" },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }
    async v4() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/leCOQyMg1Vs" },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }
    async v5() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/PWRRpHuYnKQ  " },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }
    async v6() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/EcazOuhD9dg" },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }
    async v7() {
        const toast = await this.toastController.create({
            message: 'Video is coming soon..',
            duration: 2500,
            position: 'top'
        });
        toast.present();
        // const modal = await this.modalController.create({
        //     component: VideoPopupModelComponent,
        //     cssClass: 'video-modal',
        //     componentProps: { VideoLink: "https://www.youtube.com/embed/mkdNWHd3aAs" },

        // });
        // await modal.present();
        // await modal.onDidDismiss()

        //     .then((data) => {
        //         console.log('Selected Cart Items from Dilogs ', data);
        //         if (data) {
        //             // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
        //             //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
        //         }
        //     });
    }
    async v8() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/Src_UkdKqQQ" },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }
    async v9() {
        const modal = await this.modalCtrl.create({
            component: VideoPopupModelComponent,
            cssClass: 'video-modal',
            componentProps: { VideoLink: "https://www.youtube.com/embed/2aBtDWDmLlM" },

        });
        await modal.present();
        await modal.onDidDismiss()

            .then((data) => {
                console.log('Selected Cart Items from Dilogs ', data);
                if (data) {
                    // this.getOrderData(data.data[0].SelStatus,data.data[1].SelOrderType,"",
                    //   data.data[2].SelFromDate,data.data[3].SelToDate,data.data[4].SelSortBy);
                }
            });
    }

    next(path){
        // this.route.navigate(["leaderstep"]);
        this.router.navigateByUrl(path, { replaceUrl: true });
      }
      prev(path){
        // this.route.navigate(["leaderstep"]);
        this.router.navigateByUrl(path, { replaceUrl: true });
      }
}

